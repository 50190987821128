export enum UserRole {
  employee = 'employee',
  admin = 'admin',
}

export interface WithResults<T> {
  count?: number;
  current_page?: number;
  per_page?: number;
  total_pages?: number;
  results?: T[];
}

export interface Tokens {
  access: string;
  refresh: string;
}

export interface SessionData {
  id: number;
  user: any;
  role: string;
  organization: any;
  available_applications: { [key: string]: any };
  available_organizations: any[];
  pinned_applications: any[];
  current_application: {
    application_role: Record<string, string>;
    type: string;
    name: string;
    token: string;
    settings: Record<string, string>;
  } | null;
}

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CustomTokenRefresh } from '../models/CustomTokenRefresh';
import type { DeleteAccout } from '../models/DeleteAccout';
import type { RestorePass } from '../models/RestorePass';
import type { TokenServiceVerify } from '../models/TokenServiceVerify';
import type { TokenUserAuth } from '../models/TokenUserAuth';
import type { TokenVerify } from '../models/TokenVerify';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class AuthorizationService {
  /**
   * @param data
   * @returns TokenUserAuth
   * @throws ApiError
   */
  public static authorizationTokenCreate(data: TokenUserAuth): CancelablePromise<TokenUserAuth> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/authorization/token/',
      body: data,
    });
  }
  /**
   * @param ordering Which field to use when ordering the results.
   * @param search A search term.
   * @param page A page number within the paginated result set.
   * @param perPage Number of results to return per page.
   * @returns any
   * @throws ApiError
   */
  public static authorizationTokenCookieList(
    ordering?: string,
    search?: string,
    page?: number,
    perPage?: number,
  ): CancelablePromise<{
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<CustomTokenRefresh>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/authorization/token/cookie/',
      query: {
        ordering: ordering,
        search: search,
        page: page,
        per_page: perPage,
      },
    });
  }
  /**
   * @returns any
   * @throws ApiError
   */
  public static authorizationTokenLogoutList(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/authorization/token/logout/',
    });
  }
  /**
   * @param data
   * @returns CustomTokenRefresh
   * @throws ApiError
   */
  public static authorizationTokenRefreshCreate(
    data: CustomTokenRefresh,
  ): CancelablePromise<CustomTokenRefresh> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/authorization/token/refresh/',
      body: data,
    });
  }
  /**
   * @param data
   * @returns TokenServiceVerify
   * @throws ApiError
   */
  public static authorizationTokenServiceVerifyCreate(
    data: TokenServiceVerify,
  ): CancelablePromise<TokenServiceVerify> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/authorization/token/service/verify/',
      body: data,
    });
  }
  /**
   * @param data
   * @returns TokenVerify
   * @throws ApiError
   */
  public static authorizationTokenVerifyCreate(data: TokenVerify): CancelablePromise<TokenVerify> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/authorization/token/verify/',
      body: data,
    });
  }
  /**
   * @param data
   * @returns DeleteAccout
   * @throws ApiError
   */
  public static authorizationUserDeleteAccountCreate(
    data: DeleteAccout,
  ): CancelablePromise<DeleteAccout> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/authorization/user/delete-account/',
      body: data,
    });
  }
  /**
   * @param data
   * @returns RestorePass
   * @throws ApiError
   */
  public static authorizationUserRestoreCreate(data: RestorePass): CancelablePromise<RestorePass> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/authorization/user/restore/',
      body: data,
    });
  }
}

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AccountBillingEdit } from '../models/AccountBillingEdit';
import type { AddUserInOrganization } from '../models/AddUserInOrganization';
import type { CurrentAccountUser } from '../models/CurrentAccountUser';
import type { CurrentOrganizationUser } from '../models/CurrentOrganizationUser';
import type { NotificationServiceUser } from '../models/NotificationServiceUser';
import type { UserEdit } from '../models/UserEdit';
import type { UserInvite } from '../models/UserInvite';
import type { UserInviteAccept } from '../models/UserInviteAccept';
import type { UserSearch } from '../models/UserSearch';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class UsersService {
  /**
   * @param data
   * @returns AddUserInOrganization
   * @throws ApiError
   */
  public static usersAddInOrganizationCreate(
    data: AddUserInOrganization,
  ): CancelablePromise<AddUserInOrganization> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/users/add-in-organization/',
      body: data,
    });
  }
  /**
   * @param data
   * @returns AccountBillingEdit
   * @throws ApiError
   */
  public static usersBillingEditUpdate(
    data: AccountBillingEdit,
  ): CancelablePromise<AccountBillingEdit> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/users/billing-edit/',
      body: data,
    });
  }
  /**
   * @param data
   * @returns UserEdit
   * @throws ApiError
   */
  public static usersEditUpdate(data: UserEdit): CancelablePromise<UserEdit> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/users/edit/',
      body: data,
    });
  }
  /**
   * @param data
   * @returns UserInviteAccept
   * @throws ApiError
   */
  public static usersInviteAcceptCreate(
    data: UserInviteAccept,
  ): CancelablePromise<UserInviteAccept> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/users/invite-accept/',
      body: data,
    });
  }
  /**
   * @param data
   * @returns UserInvite
   * @throws ApiError
   */
  public static usersInviteCreate(data: UserInvite): CancelablePromise<UserInvite> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/users/invite/',
      body: data,
    });
  }
  /**
   * @returns CurrentAccountUser
   * @throws ApiError
   */
  public static usersLoginAdminCreate(): CancelablePromise<CurrentAccountUser> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/users/login/admin/',
    });
  }
  /**
   * @returns CurrentOrganizationUser
   * @throws ApiError
   */
  public static usersLoginUserCreate(): CancelablePromise<CurrentOrganizationUser> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/users/login/user/',
    });
  }
  /**
   * @param data
   * @returns NotificationServiceUser
   * @throws ApiError
   */
  public static usersNotificationServiceSyncCreate(
    data: NotificationServiceUser,
  ): CancelablePromise<NotificationServiceUser> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/users/notification-service-sync/',
      body: data,
    });
  }
  /**
   * @param email
   * @param ordering Which field to use when ordering the results.
   * @param search A search term.
   * @param page A page number within the paginated result set.
   * @param perPage Number of results to return per page.
   * @returns any
   * @throws ApiError
   */
  public static usersSearchList(
    email: string,
    ordering?: string,
    search?: string,
    page?: number,
    perPage?: number,
  ): CancelablePromise<{
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<UserSearch>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/users/search/',
      query: {
        ordering: ordering,
        search: search,
        page: page,
        per_page: perPage,
        email: email,
      },
    });
  }
}

import { createStyles } from 'antd-style';

export const useStyles = createStyles(({ css, token }) => {
  return {
    roleSelect: css`
      width: 340px;
    `,
    paginationBgColor: css`
      .ant-pagination {
        margin: 0 !important;
        padding: ${token.paddingXS}px;
        background-color: #eaedef;
        border-bottom-left-radius: ${token.borderRadius}px;
        border-bottom-right-radius: ${token.borderRadius}px;
        border-right: 1px solid ${token.colorBorder};
        border-bottom: 1px solid ${token.colorBorder};
        border-left: 1px solid ${token.colorBorder};
      }
    `,
  };
});

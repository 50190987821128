import * as React from 'react';
import { Col, Flex, Row, Typography } from 'antd';
import {
  ApplicationsServiceApplicationsReadDefaultResponse,
  useApplicationsServiceApplicationsRead,
} from 'api/queries';
import { SessionContext } from 'contexts/SessionContext';
import { ApplicationItem } from 'features/applications';

const { Title } = Typography;

export const Applications = () => {
  const { session } = React.useContext(SessionContext);

  const { data: applications } = useApplicationsServiceApplicationsRead<
    ApplicationsServiceApplicationsReadDefaultResponse[]
  >(
    {
      organizationId: String(session?.data?.organization?.id),
    },
    [],
    { enabled: !!session?.data?.organization?.id },
  );

  return (
    <>
      <Row className="mb-40">
        <Col>
          <Title level={3}>Applications</Title>
        </Col>
      </Row>
      <Flex gap={48} align="center" className="ml-20">
        {applications?.map((application) => (
          <ApplicationItem key={application.id} application={application} />
        ))}
      </Flex>
    </>
  );
};

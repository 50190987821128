/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ConfirmUser } from '../models/ConfirmUser';
import type { CreateAccount } from '../models/CreateAccount';
import type { EditAccount } from '../models/EditAccount';
import type { EditUser } from '../models/EditUser';
import type { RestorePassword } from '../models/RestorePassword';
import type { Service } from '../models/Service';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class AccountService {
  /**
   * @param ordering Which field to use when ordering the results.
   * @param search A search term.
   * @param page A page number within the paginated result set.
   * @param perPage Number of results to return per page.
   * @returns any
   * @throws ApiError
   */
  public static accountList(
    ordering?: string,
    search?: string,
    page?: number,
    perPage?: number,
  ): CancelablePromise<{
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<CreateAccount>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/account/',
      query: {
        ordering: ordering,
        search: search,
        page: page,
        per_page: perPage,
      },
    });
  }
  /**
   * @param data
   * @returns CreateAccount
   * @throws ApiError
   */
  public static accountCreate(data: CreateAccount): CancelablePromise<CreateAccount> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/account/',
      body: data,
    });
  }
  /**
   * @param data
   * @returns RestorePassword
   * @throws ApiError
   */
  public static accountConfirmRestoreCreate(
    data: RestorePassword,
  ): CancelablePromise<RestorePassword> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/account/confirm-restore/',
      body: data,
    });
  }
  /**
   * @param token
   * @param ordering Which field to use when ordering the results.
   * @param search A search term.
   * @param page A page number within the paginated result set.
   * @param perPage Number of results to return per page.
   * @returns any
   * @throws ApiError
   */
  public static accountConfirmList(
    token: string,
    ordering?: string,
    search?: string,
    page?: number,
    perPage?: number,
  ): CancelablePromise<{
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<ConfirmUser>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/account/confirm/',
      query: {
        ordering: ordering,
        search: search,
        page: page,
        per_page: perPage,
        token: token,
      },
    });
  }
  /**
   * @param ordering Which field to use when ordering the results.
   * @param search A search term.
   * @param page A page number within the paginated result set.
   * @param perPage Number of results to return per page.
   * @returns any
   * @throws ApiError
   */
  public static accountServicesList(
    ordering?: string,
    search?: string,
    page?: number,
    perPage?: number,
  ): CancelablePromise<{
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<Service>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/account/services/',
      query: {
        ordering: ordering,
        search: search,
        page: page,
        per_page: perPage,
      },
    });
  }
  /**
   * @param data
   * @returns EditUser
   * @throws ApiError
   */
  public static accountUserPartialUpdate(data: EditUser): CancelablePromise<EditUser> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/account/user/',
      body: data,
    });
  }
  /**
   * @param id
   * @param data
   * @returns EditAccount
   * @throws ApiError
   */
  public static accountPartialUpdate(
    id: string,
    data: EditAccount,
  ): CancelablePromise<EditAccount> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/account/{id}/',
      path: {
        id: id,
      },
      body: data,
    });
  }
  /**
   * @param id
   * @returns void
   * @throws ApiError
   */
  public static accountDelete(id: string): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/account/{id}/',
      path: {
        id: id,
      },
    });
  }
}

import * as React from 'react';
import { Link } from 'react-router-dom';
import { Menu, Image, Dropdown } from 'antd';
import { InsertRowBelowOutlined, UserOutlined } from '@ant-design/icons';
import ProLayout, { PageContainer } from '@ant-design/pro-layout';
import { useUserPermissions, useActivePathname } from 'hooks';
import { SessionContext } from 'contexts/SessionContext';
import { Avatar } from 'components/Avatar';
import Logo from 'assets/images/logo.svg';
import { proLayoutTheme } from './styles';

export const AppLayout = ({ children }) => {
  const { session, onLogout } = React.useContext(SessionContext);
  const { isAdmin } = useUserPermissions();
  const activeKeys = useActivePathname();

  const actions = [
    {
      key: 'log-out',
      label: <div onClick={onLogout}>Log Out</div>,
    },
  ];

  const menuItems = [
    {
      icon: <InsertRowBelowOutlined />,
      key: 'applications',
      label: <Link to="/applications">Applications</Link>,
    },

    ...(isAdmin
      ? [
          {
            icon: <UserOutlined />,
            key: 'users',
            label: <Link to="/users">Users</Link>,
          },
        ]
      : []),
  ];

  return (
    <ProLayout
      logo={Logo}
      title=""
      headerTitleRender={(logo) => (
        <div>
          <Image src={(logo as Record<string, any>)?.props?.src} preview={false} height={40} />
        </div>
      )}
      menuContentRender={() => (
        <Menu
          items={menuItems}
          defaultOpenKeys={activeKeys}
          defaultSelectedKeys={activeKeys}
          mode="inline"
          inlineIndent={18}
        />
      )}
      avatarProps={{
        render: () =>
          (
            <Dropdown menu={{ items: actions }} trigger={['click']} placement="bottomRight" arrow>
              <div>
                <Avatar
                  type={session?.data?.user?.photo ? 'light' : 'primary'}
                  user={session?.data?.user}
                  src={session?.data?.user?.photo}
                />
              </div>
            </Dropdown>
          ) || <></>,
      }}
      fixSiderbar
      layout="mix"
      token={proLayoutTheme}
      siderWidth={240}
    >
      <PageContainer title={false} content={children} />
    </ProLayout>
  );
};

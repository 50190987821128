import React from 'react';
import { Card, Image, Typography } from 'antd';
import { useStyles } from 'features/authentication';
import Logo from 'assets/images/logo.svg';

const { Title } = Typography;

type Props = {
  title?: string;
  children?: React.ReactNode;
};

export const Authentication = ({ title, children }: Props) => {
  const { styles } = useStyles();

  return (
    <div className={styles.auth}>
      <div className="mb-40">
        <Image src={Logo} preview={false} height={50} />
      </div>
      <Card className={styles.container}>
        {title && (
          <Title level={2} className="mb-30">
            {title}
          </Title>
        )}

        {children}
      </Card>
    </div>
  );
};

import * as React from 'react';
import { Table } from 'antd';
import type { MenuProps } from 'antd';
import { ActionDropdown } from 'components/ActionDropdown';
import { OrganizationsServiceOrganizationsUsersReadDefaultResponse } from 'api/queries';
import { useStyles } from 'features/users';

type Props = {
  data?: OrganizationsServiceOrganizationsUsersReadDefaultResponse;
  isLoading: boolean;
  actionTable: (id: number) => MenuProps['items'];
  pagination: Record<string, number>;
  onChangePagination: (page: number, pageSize?: number) => void;
};

export const UsersTable = ({
  data,
  isLoading,
  actionTable,
  pagination,
  onChangePagination,
}: Props) => {
  const { styles } = useStyles();

  const columns = [
    {
      title: 'User',
      dataIndex: 'user',
      render: ({ first_name, last_name }) => [first_name, last_name].join(' '),
    },
    {
      title: 'Email',
      dataIndex: ['user', 'email'],
    },
    {
      title: 'Job position',
      dataIndex: ['user', 'position'],
    },
    {
      title: 'Role',
      dataIndex: 'role',
    },
    {
      title: 'Status',
      dataIndex: 'status',
    },
    {
      width: 30,
      render: ({ id }) => <ActionDropdown items={actionTable(id)} />,
    },
  ];

  return (
    <Table
      dataSource={data?.users}
      showSorterTooltip={false}
      bordered
      rowKey="id"
      columns={columns}
      loading={isLoading}
      className={styles.paginationBgColor}
      pagination={{
        total: data?.count,
        pageSize: pagination.perPage,
        current: pagination?.page,
        onChange: (page, perPage) => onChangePagination(page, perPage),
      }}
    />
  );
};

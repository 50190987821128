/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type OrganizationAccessUpdate = {
  role: OrganizationAccessUpdate.role;
};
export namespace OrganizationAccessUpdate {
  export enum role {
    ADMIN = 'admin',
    EMPLOYEE = 'employee',
  }
}

import * as React from 'react';
import { Link } from 'react-router-dom';
import { Alert, Card, Flex, Typography } from 'antd';
import { SlidersOutlined, SolutionOutlined, RightOutlined } from '@ant-design/icons';
import { useStyles } from 'features/applications';
import { Application } from 'api/requests';

const { Title, Text } = Typography;

type Props = {
  application: Application;
};

const icons = { CMS: <SlidersOutlined />, CRM: <SolutionOutlined /> };

export const ApplicationItem = ({ application }: Props) => {
  const { styles } = useStyles();

  return (
    <Card className={styles.appCard}>
      <div className={styles.iconBox}>{icons[application.name]}</div>
      <div>
        <Title level={3}>{application.name}</Title>

        {application.instance_type?.description && (
          <Text>{application.instance_type?.description}</Text>
        )}
      </div>

      {application.instance_type?.domain ? (
        <Link to={`${application.instance_type?.domain}`} target="_blank" rel="noopener noreferrer">
          <Flex gap="small" align="center" className={styles.appButton}>
            <p>Select</p>
            <RightOutlined />
          </Flex>
        </Link>
      ) : (
        <Alert message="Domain is missing, contact support!" type="warning" showIcon />
      )}
    </Card>
  );
};

import { clients } from 'api/sso/axios';
import { Tokens } from 'types';

export const auth = {
  loginUser: async (data: { password: string; username: string }) => {
    const response = await clients.sso.post('/authorization/token/', data);
    return response.data;
  },

  checkCookie: async () => {
    try {
      const response = await clients.sso.get('/authorization/token/cookie/');
      return [response.data, null] as [Tokens, null];
    } catch (error) {
      return [null, error] as [null, unknown];
    }
  },

  refreshToken: async (refresh: string) => {
    try {
      const response = await clients.sso.post('/authorization/token/refresh/', {
        refresh,
      });
      return [response.data, null] as [Tokens, null];
    } catch (error) {
      return [null, error] as [null, unknown];
    }
  },

  logoutUser: async () => {
    try {
      const response = await clients.sso.get('/authorization/token/logout/');
      return [response.data, null];
    } catch (error) {
      return [null, error];
    }
  },
};

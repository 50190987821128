import { CurrentOrganizationUser } from 'api/requests';

export enum ServiceTypes {
  SSO = 'SSO',
  ORGANIZATION = 'ORGANIZATION',
  FS = 'FS',
}

export interface ServiceConfig {
  baseUrl: string;
  token?: string;
}

export interface Tokens {
  access: string;
  refresh: string;
}

export interface Session {
  data: CurrentOrganizationUser | null;
  tokens: Tokens;
}

export interface ConfigTypes {
  [ServiceTypes.SSO]?: ServiceConfig;
  [ServiceTypes.ORGANIZATION]?: ServiceConfig;
  [ServiceTypes.FS]: ServiceConfig;
}

export enum StatusTypes {
  PENDING,
  RESOLVED,
  REJECTED,
}

export interface SessionState {
  status: StatusTypes;
  session: Session | null;
  error: Error | null | unknown;
}

export interface SessionContextState {
  session: Session | null;
  onLogin: (tokens: Tokens) => void;
  onLogout: () => void;
}

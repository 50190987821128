import { createStyles } from 'antd-style';
import HeroBg from 'assets/images/hero-bg.svg';

export const useStyles = createStyles(({ css }) => {
  return {
    auth: css`
      min-height: 100vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-image: url(${HeroBg});
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    `,
    container: css`
      width: 460px;
      padding-top: 20px;
      padding-bottom: 40px;
    `,
    icon: css`
      color: #333333;
    `,
  };
});

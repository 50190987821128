import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import { useMutation } from '@tanstack/react-query';
import { Form, Input, Flex, Button, notification } from 'antd';
import { LockOutlined, MailOutlined } from '@ant-design/icons';
import { NonFieldErrors } from 'types';
import { auth } from 'api';
import { APPLICATIONS_URL } from 'app-constants';
import { SessionContext, Tokens } from 'contexts/SessionContext';
import { Authentication, useStyles } from 'features/authentication';

export const LoginForm = () => {
  const { styles } = useStyles();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const { onLogin } = React.useContext(SessionContext);

  const { mutate } = useMutation({
    mutationFn: auth.loginUser,
    onSuccess: (data: Tokens) => {
      onLogin(data);
      navigate({ pathname: APPLICATIONS_URL }, { relative: 'path' });
    },
    onError: (error) => {
      if (error instanceof AxiosError<NonFieldErrors>) {
        notification.error({ message: error?.response?.data?.non_field_errors?.[0] });
      }
    },
  });

  const onSubmit = (values) => {
    mutate(values);
  };

  return (
    <Authentication title="Authenticate">
      <Form layout="vertical" form={form} onFinish={onSubmit}>
        <Form.Item
          label="Email"
          name="username"
          rules={[{ required: true, message: 'E-mail is required.' }, { type: 'email' }]}
        >
          <Input
            size="large"
            placeholder="Enter email"
            suffix={<MailOutlined className={styles.icon} />}
          />
        </Form.Item>
        <Form.Item label="Password" name="password" rules={[{ required: true }]}>
          <Input.Password placeholder="••••••••" size="large" />
        </Form.Item>

        <Flex justify="space-between" className="mt-30">
          <Button
            type="link"
            size="small"
            icon={<LockOutlined />}
            onClick={() =>
              navigate({ pathname: '/authentication/reset-password' }, { relative: 'path' })
            }
          >
            Forgot password ?
          </Button>

          <Button type="primary" htmlType="submit">
            Authenticate
          </Button>
        </Flex>
      </Form>
    </Authentication>
  );
};

import * as React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider, QueryCache } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { App as AntdApp, ConfigProvider, notification } from 'antd';
import en_US from 'antd/locale/en_US';
import { OpenAPI } from 'api/requests';
import { AppSession } from 'App';
import SessionProvider from 'contexts/SessionContext/SessionProvider';
import { GlobalStyleUtils, defaultTheme } from 'styles';

OpenAPI.BASE = process.env.REACT_APP_BASE_API!;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
  queryCache: new QueryCache({
    onError: () =>
      notification.error({
        message: 'Error',
        description: 'Something went wrong',
      }),
  }),
});

const ConfigProviderApp = ({ ...props }) => {
  return (
    <AntdApp>
      <ConfigProvider theme={defaultTheme} locale={en_US} {...props} />
    </AntdApp>
  );
};

export const App = () => (
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <ConfigProviderApp>
        <GlobalStyleUtils />
        <SessionProvider>
          <AppSession />
        </SessionProvider>
      </ConfigProviderApp>

      {process.env.NODE_ENV === 'development' && <ReactQueryDevtools initialIsOpen={false} />}
    </QueryClientProvider>
  </BrowserRouter>
);

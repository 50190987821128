/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Application } from '../models/Application';
import type { ApplicationAccessCreate } from '../models/ApplicationAccessCreate';
import type { ApplicationAccessRead } from '../models/ApplicationAccessRead';
import type { ApplicationRepository } from '../models/ApplicationRepository';
import type { ApplicationUserDetails } from '../models/ApplicationUserDetails';
import type { ApplicationValidate } from '../models/ApplicationValidate';
import type { OrganizationTeamShort } from '../models/OrganizationTeamShort';
import type { UserShort } from '../models/UserShort';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ApplicationsService {
  /**
   * @param organizationId
   * @param data
   * @returns ApplicationAccessCreate
   * @throws ApiError
   */
  public static applicationsAccessCreate(
    organizationId: string,
    data: ApplicationAccessCreate,
  ): CancelablePromise<ApplicationAccessCreate> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/applications/access/{organization_id}/',
      path: {
        organization_id: organizationId,
      },
      body: data,
    });
  }
  /**
   * @param organizationAccessId
   * @param organizationId
   * @returns ApplicationAccessRead
   * @throws ApiError
   */
  public static applicationsAccessRead(
    organizationAccessId: string,
    organizationId: string,
  ): CancelablePromise<ApplicationAccessRead> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/applications/access/{organization_id}/{organization_access_id}/',
      path: {
        organization_access_id: organizationAccessId,
        organization_id: organizationId,
      },
    });
  }
  /**
   * @param appToken
   * @returns void
   * @throws ApiError
   */
  public static applicationsRemoveDelete(appToken: string): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/applications/remove/{app_token}/',
      path: {
        app_token: appToken,
      },
    });
  }
  /**
   * @param organizationId
   * @returns ApplicationRepository
   * @throws ApiError
   */
  public static applicationsRepositoryRead(
    organizationId: string,
  ): CancelablePromise<ApplicationRepository> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/applications/repository/{organization_id}/',
      path: {
        organization_id: organizationId,
      },
    });
  }
  /**
   * @param appToken
   * @param ordering Which field to use when ordering the results.
   * @param search A search term.
   * @param page A page number within the paginated result set.
   * @param pageSize Number of results to return per page.
   * @returns any
   * @throws ApiError
   */
  public static applicationsTeamListList(
    appToken: string,
    ordering?: string,
    search?: string,
    page?: number,
    pageSize?: number,
  ): CancelablePromise<{
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<OrganizationTeamShort>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/applications/team/{app_token}/list/',
      path: {
        app_token: appToken,
      },
      query: {
        ordering: ordering,
        search: search,
        page: page,
        page_size: pageSize,
      },
    });
  }
  /**
   * @param appToken
   * @param ordering Which field to use when ordering the results.
   * @param search A search term.
   * @param page A page number within the paginated result set.
   * @param perPage Number of results to return per page.
   * @returns any
   * @throws ApiError
   */
  public static applicationsUserCanAccessList(
    appToken: string,
    ordering?: string,
    search?: string,
    page?: number,
    perPage?: number,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/applications/user/{app_token}/can-access/',
      path: {
        app_token: appToken,
      },
      query: {
        ordering: ordering,
        search: search,
        page: page,
        per_page: perPage,
      },
    });
  }
  /**
   * @param appToken
   * @param ordering Which field to use when ordering the results.
   * @param search A search term.
   * @param page A page number within the paginated result set.
   * @param perPage Number of results to return per page.
   * @returns any
   * @throws ApiError
   */
  public static applicationsUserDetailsList(
    appToken: string,
    ordering?: string,
    search?: string,
    page?: number,
    perPage?: number,
  ): CancelablePromise<{
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<ApplicationUserDetails>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/applications/user/{app_token}/details',
      path: {
        app_token: appToken,
      },
      query: {
        ordering: ordering,
        search: search,
        page: page,
        per_page: perPage,
      },
    });
  }
  /**
   * @param appToken
   * @param search A search term.
   * @param page A page number within the paginated result set.
   * @param pageSize Number of results to return per page.
   * @returns any
   * @throws ApiError
   */
  public static applicationsUserListList(
    appToken: string,
    search?: string,
    page?: number,
    pageSize?: number,
  ): CancelablePromise<{
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<UserShort>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/applications/user/{app_token}/list/',
      path: {
        app_token: appToken,
      },
      query: {
        search: search,
        page: page,
        page_size: pageSize,
      },
    });
  }
  /**
   * @param appToken
   * @returns ApplicationValidate
   * @throws ApiError
   */
  public static applicationsValidateRead(appToken: string): CancelablePromise<ApplicationValidate> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/applications/validate/{app_token}/',
      path: {
        app_token: appToken,
      },
    });
  }
  /**
   * @param organizationId
   * @param orderBy
   * @param orderDirection
   * @returns Application
   * @throws ApiError
   */
  public static applicationsRead(
    organizationId: string,
    orderBy: 'name' | 'date_created' = 'date_created',
    orderDirection: 'asc' | 'desc' = 'asc',
  ): CancelablePromise<Application> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/applications/{organization_id}/',
      path: {
        organization_id: organizationId,
      },
      query: {
        order_by: orderBy,
        order_direction: orderDirection,
      },
    });
  }
  /**
   * @param organizationId
   * @param ordering Which field to use when ordering the results.
   * @param search A search term.
   * @param page A page number within the paginated result set.
   * @param perPage Number of results to return per page.
   * @returns any
   * @throws ApiError
   */
  public static applicationsAllList(
    organizationId: string,
    ordering?: string,
    search?: string,
    page?: number,
    perPage?: number,
  ): CancelablePromise<{
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<Application>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/applications/{organization_id}/all',
      path: {
        organization_id: organizationId,
      },
      query: {
        ordering: ordering,
        search: search,
        page: page,
        per_page: perPage,
      },
    });
  }
}

import * as React from 'react';
import { Avatar as AvatarAntd, Badge, Flex, Typography } from 'antd';
import type { AvatarProps } from 'antd';
import { PresetStatusColorType } from 'antd/lib/_util/colors';
import { AvatarType, getUserFullName } from './utils';
import { useStyles } from './styles';
import { UserShort } from 'api/requests';

const { Text } = Typography;

export interface AvatarCustomProps extends AvatarProps {
  user?: UserShort;
  shortName?: boolean;
  status?: PresetStatusColorType;
  type?: AvatarType;
  description?: string;
  showAvatar?: boolean;
  vertical?: boolean;
  reverse?: boolean;
}

export const Avatar = ({
  user,
  shortName,
  type,
  status,
  description,
  showAvatar = true,
  vertical,
  reverse = false,
  src,
  ...props
}: AvatarCustomProps) => {
  const { styles } = useStyles({ type, user, reverse });

  return !user && !src ? null : (
    <Flex className={styles.avatarContent} gap={6} vertical={vertical} align="center">
      {showAvatar && (
        <Badge status={status} dot={!!status}>
          <AvatarAntd className={styles.avatar} src={src} {...props}>
            {getUserFullName(user, true)}
          </AvatarAntd>
        </Badge>
      )}

      {!shortName && (
        <Flex vertical gap={0} align="center">
          <Text>{getUserFullName(user)}</Text>

          {description && <Text>{description}</Text>}
        </Flex>
      )}
    </Flex>
  );
};

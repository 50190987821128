import * as React from 'react';
import { Form, Input } from 'antd';
import { useQueryClient } from '@tanstack/react-query';
import {
  useOrganizationsServiceOrganizationsUserEditPartialUpdate,
  useOrganizationsServiceOrganizationsUserRead,
  useOrganizationsServiceOrganizationsUserReadKey,
  useOrganizationsServiceOrganizationsUsersReadKey,
} from 'api/queries';

type Props = {
  id: number;
  name: string;
  onClose: () => void;
};

export const UserEditForm = ({ id, name, onClose }: Props) => {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const { data } = useOrganizationsServiceOrganizationsUserRead<any>(
    { id },
    [useOrganizationsServiceOrganizationsUserReadKey],
    { enabled: !!id },
  );

  React.useEffect(() => {
    if (data) {
      form.setFieldsValue({
        first_name: data?.user?.first_name,
        last_name: data?.user?.last_name,
        phone: data?.user?.phone,
        position: data?.user?.position,
      });
    }
  }, [data]);

  const { mutate } = useOrganizationsServiceOrganizationsUserEditPartialUpdate({
    onSuccess: () => {
      onClose();
      queryClient.invalidateQueries({
        queryKey: [useOrganizationsServiceOrganizationsUsersReadKey],
      });
    },
  });

  const onSubmit = (values) => mutate({ data: values, ssoId: data.user.id });

  return (
    <Form name={name} form={form} layout="vertical" onFinish={onSubmit}>
      <Form.Item
        name="first_name"
        label="First name"
        rules={[{ required: true, message: 'First name is required.' }]}
      >
        <Input placeholder="Enter first name" size="large" />
      </Form.Item>
      <Form.Item
        name="last_name"
        label="Last name"
        rules={[{ required: true, message: 'Last name is required.' }]}
      >
        <Input size="large" placeholder="Enter last name" />
      </Form.Item>

      <Form.Item name="phone" label="Phone">
        <Input placeholder="Enter phone number" size="large" type="number" min={0} />
      </Form.Item>

      <Form.Item name="position" label="Job position">
        <Input placeholder="Enter job position" size="large" />
      </Form.Item>
    </Form>
  );
};

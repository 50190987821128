import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { Button, Card, Col, Divider, Flex, Form, Row, Typography } from 'antd';
import {
  useApplicationsServiceApplicationsAccessRead,
  useApplicationsServiceApplicationsAccessReadKey,
  useApplicationsServiceApplicationsAccessCreate,
  useOrganizationsServiceOrganizationsUsersReadKey,
  useApplicationsServiceApplicationsAllList,
  useApplicationsServiceApplicationsAllListKey,
} from 'api/queries';
import type { Application } from 'api/requests';
import {
  UserSteps,
  formatUserPermissions,
  UserPermissionRole,
  getAllowedAppById,
} from 'features/users';
import { useURLParams } from 'hooks/useURLParams';
import { SessionContext } from 'contexts/SessionContext';

const { Title } = Typography;

export const UserPermissions = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { id = '' } = useParams();
  const urlSearchParams = useURLParams();
  const stepType = urlSearchParams.get('type');
  const [form] = Form.useForm();
  const { session } = React.useContext(SessionContext);

  const { data: applications } = useApplicationsServiceApplicationsAllList<Array<Application>>(
    {
      organizationId: String(session?.data?.organization?.id),
    },
    [],
    { enabled: !!session?.data?.organization?.id },
  );

  const { data: userApplications } = useApplicationsServiceApplicationsAccessRead(
    {
      organizationAccessId: id,
      organizationId: String(session?.data?.organization?.id),
    },
    [useApplicationsServiceApplicationsAccessReadKey],
    { enabled: !!id && !!session?.data?.organization?.id },
  );

  const { mutate, isPending } = useApplicationsServiceApplicationsAccessCreate({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [useOrganizationsServiceOrganizationsUsersReadKey],
      });
      queryClient.invalidateQueries({
        queryKey: [useApplicationsServiceApplicationsAccessReadKey],
      });
      queryClient.invalidateQueries({
        queryKey: [useApplicationsServiceApplicationsAllListKey],
      });
      navigate('/users', { relative: 'path' });
    },
  });

  const onSubmit = (values) => {
    if (session?.data?.organization?.id && id) {
      mutate({
        organizationId: String(session?.data?.organization?.id),
        data: { users: [Number(id)], applications: formatUserPermissions(values) },
      });
    }
  };

  return (
    <>
      <Row className="mb-20">
        <Col>
          <Title level={3}>Permissions</Title>
        </Col>
      </Row>

      {stepType && (
        <Row className="mb-30">
          <Col span={24} sm={24} md={20} lg={16} xl={14} xxl={10}>
            <UserSteps current={1} />
          </Col>
        </Row>
      )}

      <Row className="mb-30">
        <Col span={24} sm={24} md={20} lg={16} xl={14} xxl={10}>
          <Card>
            <Form form={form} onFinish={onSubmit}>
              {applications?.map((application) => (
                <React.Fragment key={application.id}>
                  <div>
                    <Title level={4} className="mb-10">
                      {application.name}
                    </Title>
                    <UserPermissionRole
                      application={application}
                      allowedApp={getAllowedAppById(userApplications, application.id)}
                      form={form}
                    />
                  </div>
                  <Divider />
                </React.Fragment>
              ))}

              <Flex justify="space-between">
                <Button onClick={() => navigate(-1)}>Cancel</Button>
                <Button type="primary" htmlType="submit" loading={isPending}>
                  Save
                </Button>
              </Flex>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
};

import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useCookie } from 'react-use';
import { Spin } from 'antd';
import SessionContext from 'contexts/SessionContext/SessionContext';
import { Tokens } from 'contexts/SessionContext/interface';
import { SessionState, StatusTypes } from 'contexts/SessionContext';
import { getHostname } from 'utils';
import { OpenAPI, UsersService } from 'api/requests';
import { auth } from 'api';
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY, LOGIN_URL } from 'app-constants';

const initialState: SessionState = {
  status: StatusTypes.PENDING,
  error: null,
  session: null,
};

const clearStorage = (): void => {
  window.localStorage.removeItem(ACCESS_TOKEN_KEY);
  window.localStorage.removeItem(REFRESH_TOKEN_KEY);
};

const SessionProvider = ({ children }) => {
  const navigate = useNavigate();
  const [, updateCookie] = useCookie('Authorization');
  const [, updateAppCookie] = useCookie('app_token');
  const [state, setState] = React.useState<SessionState>(initialState);
  const { status, session, error } = state;

  const getAuthTokens = async () => {
    const [tokens, error] = await auth.checkCookie();

    if (tokens) {
      setState({
        session: { tokens, data: null },
        status: StatusTypes.PENDING,
        error: null,
      });
    } else {
      setState({
        session: null,
        error,
        status: StatusTypes.REJECTED,
      });
    }
  };

  const getOrganizationUser = async () => {
    if (session?.tokens.access) {
      const data = await UsersService.usersLoginUserCreate();

      if (data && session?.tokens) {
        setState({
          session: { tokens: session?.tokens, data },
          status: StatusTypes.RESOLVED,
          error: null,
        });
      } else if (error) {
        setState({
          session: { tokens: session?.tokens, data: null },
          error,
          status: StatusTypes.REJECTED,
        });
      }
    }
  };

  // TODO would like to discuss with team lead if need refresh session
  // const refreshSession = async (): Promise<void> => {
  //   const refreshToken = window.localStorage.getItem(REFRESH_TOKEN_KEY);
  //
  //   if (refreshToken) {
  //     const [tokens] = await auth.refreshToken(refreshToken);
  //
  //     if (tokens) {
  //       setState({
  //         session: { tokens, data: null },
  //         status: StatusTypes.PENDING,
  //         error: null,
  //       });
  //     }
  //     if (tokens?.access) {
  //       await getOrganizationUser();
  //     }
  //   } else {
  //     setState({
  //       error,
  //       status: StatusTypes.REJECTED,
  //       session: null,
  //     });
  //     navigate({ pathname: LOGIN_URL }, { relative: 'path' });
  //   }
  // };

  React.useEffect(() => {
    if (!session?.tokens?.access && !session?.tokens?.refresh) {
      getAuthTokens();
    }
  }, []);

  // TODO would like to discuss with team lead if need refresh session
  // React.useEffect(() => {
  //   if (status === StatusTypes.REJECTED) {
  //     refreshSession();
  //   }
  // },
  // [status, refreshSession]);

  React.useEffect(() => {
    if (session !== null) {
      window.localStorage.setItem(ACCESS_TOKEN_KEY, session.tokens.access);
      window.localStorage.setItem(REFRESH_TOKEN_KEY, session.tokens.refresh);
      updateCookie('', { domain: `.${getHostname()}` });
      updateAppCookie('', { domain: `.${getHostname()}` });
      OpenAPI.TOKEN = session.tokens.access;
    } else {
      clearStorage();
      updateCookie('', { domain: `.${getHostname()}` });
      updateAppCookie('', { domain: `.${getHostname()}` });
    }
  }, [session]);

  React.useEffect(() => {
    if (session?.tokens?.access && session?.tokens?.refresh) {
      getOrganizationUser();
    }
  }, [session?.tokens]);

  const onLogin = (tokens: Tokens) => {
    if (tokens) {
      setState({
        session: { tokens, data: null },
        status: StatusTypes.PENDING,
        error: null,
      });
    } else {
      setState({
        session: null,
        error,
        status: StatusTypes.REJECTED,
      });
    }
  };

  const onLogout = (): void => {
    auth.logoutUser();
    setState({
      error,
      status: StatusTypes.REJECTED,
      session: null,
    });
    clearStorage();
    navigate({ pathname: LOGIN_URL }, { relative: 'path' });
  };

  if (status === StatusTypes.PENDING) {
    return (
      <div className="root-spinner">
        <Spin />
      </div>
    );
  }

  return (
    <SessionContext.Provider
      value={{
        session: state.session,
        onLogin,
        onLogout,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

export default SessionProvider;

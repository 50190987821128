export const getAllowedAppById = (applications, id) =>
  applications?.find((application) => application.application === id);

export const formatUserPermissions = (values) => {
  const permissions: { role: string; id: number }[] = [];

  Object.entries(values).forEach(([key, value]) => {
    const { role, active } = (value as Record<string, any>) || {};

    if (!active && !role) return;

    if (active && role) {
      permissions.push({ role: role, id: Number(key) });
    }
  });

  return permissions;
};

import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { useQueryClient } from '@tanstack/react-query';
import { Button, Flex, Typography, Modal } from 'antd';
import { UserAddOutlined } from '@ant-design/icons';
import {
  useOrganizationsServiceOrganizationsUserDelete,
  useOrganizationsServiceOrganizationsUsersRead,
  useOrganizationsServiceOrganizationsUsersReadKey,
} from 'api/queries';
import { SessionContext } from 'contexts/SessionContext';
import { UserEditForm, UserRoleForm, UsersTable } from 'features/users';

const { Title } = Typography;

const formNameRole = 'user-role-form';
const formNameEdit = 'user-edit-form';

export const UsersPage = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { session } = React.useContext(SessionContext);
  const [editRoleId, setEditRoleId] = React.useState<number>();
  const [editId, setEditId] = React.useState<number>();
  const [deleteId, setDeleteId] = React.useState<number>();
  const [pagination, setPagination] = React.useState<Record<string, number>>({
    page: 1,
    perPage: 10,
  });

  const { data: users, isLoading } = useOrganizationsServiceOrganizationsUsersRead(
    {
      organizationId: String(session?.data?.organization?.id),
      ...pagination,
    },
    [queryString.stringify(pagination)],
    { enabled: !!session?.data?.organization?.id },
  );

  const { mutate, isPending } = useOrganizationsServiceOrganizationsUserDelete({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [useOrganizationsServiceOrganizationsUsersReadKey],
      });
      setDeleteId(undefined);
    },
  });

  const renderTableAction = (id) => [
    { label: 'Edit user', key: 'edit_user', onClick: () => setEditId(id) },
    { label: 'Edit role', key: 'edit_role', onClick: () => setEditRoleId(id) },
    {
      label: 'Edit permission',
      key: 'edit_permission',
      onClick: () => navigate(`permissions/${id}`),
    },
    ...(session?.data?.user?.id !== id
      ? [
          {
            label: 'Delete user',
            key: 'delete_user',
            onClick: () => setDeleteId(id),
          },
        ]
      : []),
  ];

  return (
    <>
      <Flex className="mb-20" justify="space-between" align="center">
        <Title level={3}>Users</Title>

        <Button type="primary" icon={<UserAddOutlined />} onClick={() => navigate('invite')}>
          Add new user
        </Button>
      </Flex>

      <UsersTable
        data={users}
        isLoading={isLoading}
        actionTable={renderTableAction}
        pagination={pagination}
        onChangePagination={(page: number, perPage) =>
          setPagination({ page, ...(perPage && { perPage }) })
        }
      />

      <Modal
        maskClosable={false}
        open={!!editId}
        title="Edit user"
        onCancel={() => setEditId(undefined)}
        destroyOnClose
        okButtonProps={{
          htmlType: 'submit',
          form: formNameEdit,
        }}
      >
        <UserEditForm id={editId!} name={formNameEdit} onClose={() => setEditId(undefined)} />
      </Modal>

      <Modal
        maskClosable={false}
        open={!!editRoleId}
        title="Change user role"
        onCancel={() => setEditRoleId(undefined)}
        destroyOnClose
        okButtonProps={{
          htmlType: 'submit',
          form: formNameRole,
        }}
      >
        <UserRoleForm
          id={editRoleId!}
          name={formNameRole}
          onClose={() => setEditRoleId(undefined)}
        />
      </Modal>

      <Modal
        maskClosable={false}
        open={!!deleteId}
        title="Delete the user"
        onCancel={() => setDeleteId(undefined)}
        destroyOnClose
        onOk={() => mutate({ id: deleteId! })}
        confirmLoading={isPending}
      >
        <p>Are you sure to delete this user?</p>
      </Modal>
    </>
  );
};

import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PrivateRoutes, PublicRoutes } from 'routes';
import { LOGIN_URL, APPLICATIONS_URL } from 'app-constants';
import { SessionContext } from 'contexts/SessionContext';

export const AppSession = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { session } = React.useContext(SessionContext);

  React.useEffect(() => {
    if (!session && !pathname.includes('authentication/')) {
      navigate({ pathname: LOGIN_URL }, { relative: 'path' });
    }
    if (session && pathname.includes('authentication/')) {
      navigate({ pathname: APPLICATIONS_URL }, { relative: 'path' });
    }
  }, [session, pathname]);

  return (
    <>{!session || pathname.includes('authentication/') ? <PublicRoutes /> : <PrivateRoutes />}</>
  );
};

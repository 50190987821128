import { createGlobalStyle } from 'antd-style';

export const GlobalStyleUtils = createGlobalStyle`
    .mt-10 {
        margin-top: 10px !important;
    }

    .mt-20{
        margin-top: 20px !important;
    }

    .mt-30{
        margin-top: 30px !important;
    }

    .mr-10{
        margin-right: 30px !important;
    }

    .mb-0 {
        margin-bottom: 0 !important;
    }

    .mb-10 {
        margin-bottom: 10px !important;
    }

    .mb-20 {
        margin-bottom: 20px !important;
    }

    .mb-30 {
        margin-bottom: 30px !important;
    }

    .mb-40 {
        margin-bottom: 40px !important;
    }

    .ml-20 {
        margin-left: 20px !important;
    }
  
    .root-spinner {
        min-height: 50vh;
        display: flex;
        align-items: center;
        justify-content: center;      
    }
`;

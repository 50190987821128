/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type UserEdit = {
  readonly sso_id?: number;
  first_name?: string | null;
  last_name?: string | null;
  phone?: string;
  photo?: string | null;
  position?: string | null;
  language?: UserEdit.language;
  firebase_tokens?: Array<string>;
  is_active?: boolean;
};
export namespace UserEdit {
  export enum language {
    EN = 'en',
    RU = 'ru',
    RO = 'ro',
  }
}

import * as React from 'react';
import { Form, notification, Select } from 'antd';
import { useQueryClient } from '@tanstack/react-query';
import {
  useOrganizationsServiceOrganizationsUserRead,
  useOrganizationsServiceOrganizationsUserReadKey,
  useOrganizationsServiceOrganizationsUsersReadKey,
  useOrganizationsServiceOrganizationsUserUpdate,
} from 'api/queries';
import { ApiError } from 'api/requests';
import { UserRole } from 'types';

type Props = {
  id: number;
  name: string;
  onClose: () => void;
};

export const UserRoleForm = ({ id, name, onClose }: Props) => {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const { data } = useOrganizationsServiceOrganizationsUserRead(
    { id: id },
    [useOrganizationsServiceOrganizationsUserReadKey],
    { enabled: !!id },
  );

  React.useEffect(() => {
    if (data) form.setFieldValue('role', data.role);
  }, [data]);

  const { mutate } = useOrganizationsServiceOrganizationsUserUpdate({
    onError: (e: ApiError) =>
      notification.error({
        message: 'Error',
        description: e.body.role,
      }),
    onSuccess: () => {
      onClose();
      queryClient.invalidateQueries({
        queryKey: [useOrganizationsServiceOrganizationsUsersReadKey],
      });
    },
  });

  const onSubmit = (values) => mutate({ id: id, data: values });

  return (
    <Form name={name} form={form} layout="vertical" onFinish={onSubmit}>
      <Form.Item name="role" label="Select Role">
        <Select size="large">
          <Select.Option value={UserRole.admin}>Administrator</Select.Option>
          <Select.Option value={UserRole.employee}>Employee</Select.Option>
        </Select>
      </Form.Item>
    </Form>
  );
};

export const defaultTheme = {
  token: {
    fontFamily: '"Quicksand", sans-serif',
    colorPrimary: '#ff5900',
    colorInfo: '#ff5900',
    colorSuccess: '#27A25A',
    colorWarning: '#FFB900',
    colorError: '#FF5900',
    colorTextBase: '#333333',
    fontSize: 14,
    sizeStep: 4,
    borderRadius: 8,
    boxShadow:
      '0px 3px 6px 0px rgba(0, 0, 0, 0.05);\n0px 10px 10px 0px rgba(0, 0, 0, 0.04);\n0px 23px 14px 0px rgba(0, 0, 0, 0.03);\n0px 41px 16px 0px rgba(0, 0, 0, 0.01);\n0px 64px 18px 0px rgba(0, 0, 0, 0);',
    boxShadowSecondary:
      '\n0px 5px 10px 0px rgba(0, 0, 0, 0.1);\n0px 19px 19px 0px rgba(0, 0, 0, 0.09);\n0px 42px 25px 0px rgba(0, 0, 0, 0.05);\n0px 75px 30px 0px rgba(0, 0, 0, 0.01);\n0px 117px 33px 0px rgba(0, 0, 0, 0);\n',
  },
  components: {
    Form: {
      itemMarginBottom: 12,
      labelFontSize: 14,
      labelColor: '#333333',
      colorBorder: '#d7dce0',
    },
    Pagination: {
      itemSize: 40,
    },
    Select: {
      optionSelectedBg: '#eef4f9',
      optionPadding: '10px 14px',
    },
    Table: {
      borderColor: '#d7dce0',
      headerBg: '#eaedef',
    },
    Typography: {
      titleMarginBottom: 0,
    },
  },
};

import { ProLayoutProps } from '@ant-design/pro-layout';

export const proLayoutTheme: ProLayoutProps['token'] = {
  header: {
    colorBgHeader: '#fff',
  },
  bgLayout: '#eef4f9',
  sider: {
    colorMenuBackground: '#fff',
    colorBgMenuItemSelected: '#eef4f9',
  },
};

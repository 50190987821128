import * as React from 'react';
import { Steps } from 'antd';
import { StepTypes } from 'types';

type Props = {
  type?: StepTypes;
  current?: number;
};

export const UserSteps = ({ current = 0 }: Props) => {
  const items = [
    {
      title: 'Invite user',
      key: 0,
    },
    {
      title: 'Set permissions',
      content: 1,
    },
  ];

  return <Steps current={current} items={items}></Steps>;
};

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OrganizationAccessUpdate } from '../models/OrganizationAccessUpdate';
import type { OrganizationAddUser } from '../models/OrganizationAddUser';
import type { OrganizationChange } from '../models/OrganizationChange';
import type { OrganizationTeam } from '../models/OrganizationTeam';
import type { OrganizationTeamCreate } from '../models/OrganizationTeamCreate';
import type { OrganizationUserTeam } from '../models/OrganizationUserTeam';
import type { UserEdit } from '../models/UserEdit';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class OrganizationsService {
  /**
   * @param organizationId
   * @param data
   * @returns OrganizationChange
   * @throws ApiError
   */
  public static organizationsChangeUpdate(
    organizationId: string,
    data: OrganizationChange,
  ): CancelablePromise<OrganizationChange> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/organizations/change/{organization_id}/',
      path: {
        organization_id: organizationId,
      },
      body: data,
    });
  }
  /**
   * @param domainName
   * @returns any
   * @throws ApiError
   */
  public static organizationsDomainNameExistsRead(domainName: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/organizations/domain-name-exists/{domain_name}/',
      path: {
        domain_name: domainName,
      },
    });
  }
  /**
   * @param domainName
   * @returns any
   * @throws ApiError
   */
  public static organizationsInfoRead(domainName: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/organizations/info/{domain_name}/',
      path: {
        domain_name: domainName,
      },
    });
  }
  /**
   * @param id A unique integer value identifying this organization team.
   * @returns OrganizationTeam
   * @throws ApiError
   */
  public static organizationsTeamRead(id: number): CancelablePromise<OrganizationTeam> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/organizations/team/{id}/',
      path: {
        id: id,
      },
    });
  }
  /**
   * @param id A unique integer value identifying this organization team.
   * @param data
   * @returns OrganizationTeam
   * @throws ApiError
   */
  public static organizationsTeamUpdate(
    id: number,
    data: OrganizationTeam,
  ): CancelablePromise<OrganizationTeam> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/organizations/team/{id}/',
      path: {
        id: id,
      },
      body: data,
    });
  }
  /**
   * @param id A unique integer value identifying this organization team.
   * @returns void
   * @throws ApiError
   */
  public static organizationsTeamDelete(id: number): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/organizations/team/{id}/',
      path: {
        id: id,
      },
    });
  }
  /**
   * @param organizationId
   * @param data
   * @returns OrganizationTeamCreate
   * @throws ApiError
   */
  public static organizationsTeamsCreateCreate(
    organizationId: string,
    data: OrganizationTeamCreate,
  ): CancelablePromise<OrganizationTeamCreate> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/organizations/teams/create/{organization_id}/',
      path: {
        organization_id: organizationId,
      },
      body: data,
    });
  }
  /**
   * @param organizationId
   * @param ordering Which field to use when ordering the results.
   * @param search A search term.
   * @param page A page number within the paginated result set.
   * @param perPage Number of results to return per page.
   * @returns any
   * @throws ApiError
   */
  public static organizationsTeamsList(
    organizationId: string,
    ordering?: string,
    search?: string,
    page?: number,
    perPage?: number,
  ): CancelablePromise<{
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<OrganizationTeam>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/organizations/teams/{organization_id}/',
      path: {
        organization_id: organizationId,
      },
      query: {
        ordering: ordering,
        search: search,
        page: page,
        per_page: perPage,
      },
    });
  }
  /**
   * @param data
   * @returns OrganizationUserTeam
   * @throws ApiError
   */
  public static organizationsUserTeamCreate(
    data: OrganizationUserTeam,
  ): CancelablePromise<OrganizationUserTeam> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/organizations/user/team/',
      body: data,
    });
  }
  /**
   * @param teamId
   * @param userId
   * @returns void
   * @throws ApiError
   */
  public static organizationsUserTeamDelete(
    teamId: string,
    userId: string,
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/organizations/user/team/{team_id}/{user_id}/',
      path: {
        team_id: teamId,
        user_id: userId,
      },
    });
  }
  /**
   * @param id A unique integer value identifying this organization access.
   * @returns OrganizationAccessUpdate
   * @throws ApiError
   */
  public static organizationsUserRead(id: number): CancelablePromise<OrganizationAccessUpdate> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/organizations/user/{id}/',
      path: {
        id: id,
      },
    });
  }
  /**
   * @param id A unique integer value identifying this organization access.
   * @param data
   * @returns OrganizationAccessUpdate
   * @throws ApiError
   */
  public static organizationsUserUpdate(
    id: number,
    data: OrganizationAccessUpdate,
  ): CancelablePromise<OrganizationAccessUpdate> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/organizations/user/{id}/',
      path: {
        id: id,
      },
      body: data,
    });
  }
  /**
   * @param id A unique integer value identifying this organization access.
   * @returns void
   * @throws ApiError
   */
  public static organizationsUserDelete(id: number): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/organizations/user/{id}/',
      path: {
        id: id,
      },
    });
  }
  /**
   * @param ssoId A unique integer value identifying this user.
   * @param data
   * @returns UserEdit
   * @throws ApiError
   */
  public static organizationsUserEditPartialUpdate(
    ssoId: number,
    data: UserEdit,
  ): CancelablePromise<UserEdit> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/organizations/user/{sso_id}/edit/',
      path: {
        sso_id: ssoId,
      },
      body: data,
    });
  }
  /**
   * @param organizationId
   * @param data
   * @returns OrganizationAddUser
   * @throws ApiError
   */
  public static organizationsUsersCreateCreate(
    organizationId: string,
    data: OrganizationAddUser,
  ): CancelablePromise<OrganizationAddUser> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/organizations/users/create/{organization_id}/',
      path: {
        organization_id: organizationId,
      },
      body: data,
    });
  }
  /**
   * @param organizationId
   * @param page
   * @param perPage
   * @param search
   * @param teamId
   * @param orderBy
   * @param orderDirection
   * @param status
   * @returns any
   * @throws ApiError
   */
  public static organizationsUsersRead(
    organizationId: string,
    page?: number,
    perPage?: number,
    search?: string,
    teamId?: number,
    orderBy: 'first_name' | 'date_joined' = 'date_joined',
    orderDirection: 'asc' | 'desc' = 'asc',
    status: 'online' | 'offline' | 'all' = 'all',
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/organizations/users/{organization_id}/',
      path: {
        organization_id: organizationId,
      },
      query: {
        page: page,
        per_page: perPage,
        search: search,
        team_id: teamId,
        order_by: orderBy,
        order_direction: orderDirection,
        status: status,
      },
    });
  }
  /**
   * @param accountId
   * @returns any
   * @throws ApiError
   */
  public static organizationsRead(accountId: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/organizations/{account_id}/',
      path: {
        account_id: accountId,
      },
    });
  }
}

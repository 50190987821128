interface FailedFields {
  [key: string]: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any;
    errors: Error[];
  };
}

interface ApiFailedFields {
  [key: string]: string[];
}

/**
 * Error type for cases when submitted for fields
 * did not pass the validation.
 *
 * This will use the response fields and form fields
 * to expose an object which can be used in antd `form.setFields` method.
 */
export class FieldsValidationError extends Error {
  private readonly failedFields: FailedFields;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(responseFields: ApiFailedFields, formFields: any) {
    super('Failed fields validation');
    const failedFields: FailedFields = {};

    for (const key in responseFields) {
      if (Object.prototype.hasOwnProperty.call(responseFields, key)) {
        if (Object.prototype.hasOwnProperty.call(formFields, key)) {
          failedFields[key] = {
            value: formFields[key],
            errors: responseFields[key].map((s) => new Error(s)),
          };
        } else {
          // tslint:disable-next-line: no-console
          console.warn(`Missing key in form fields: ${key}`);
        }
      }
    }

    this.failedFields = failedFields;
  }

  get fields(): FailedFields {
    return this.failedFields;
  }
}

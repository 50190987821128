// generated with @7nohe/openapi-react-query-codegen@0.5.3
/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import {
  useQuery,
  useMutation,
  UseQueryResult,
  UseQueryOptions,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import { AccountAccess } from '../requests/models/AccountAccess';
import { AccountBillingEdit } from '../requests/models/AccountBillingEdit';
import { Accounts } from '../requests/models/Accounts';
import { AddUserInOrganization } from '../requests/models/AddUserInOrganization';
import { Application } from '../requests/models/Application';
import { ApplicationAccess } from '../requests/models/ApplicationAccess';
import { ApplicationAccessCreate } from '../requests/models/ApplicationAccessCreate';
import { ApplicationAccessRead } from '../requests/models/ApplicationAccessRead';
import { ApplicationRepository } from '../requests/models/ApplicationRepository';
import { ApplicationUserDetails } from '../requests/models/ApplicationUserDetails';
import { ApplicationValidate } from '../requests/models/ApplicationValidate';
import { AppRole } from '../requests/models/AppRole';
import { ConfirmUser } from '../requests/models/ConfirmUser';
import { CreateAccount } from '../requests/models/CreateAccount';
import { CurrentAccountUser } from '../requests/models/CurrentAccountUser';
import { CurrentOrganizationUser } from '../requests/models/CurrentOrganizationUser';
import { CustomTokenRefresh } from '../requests/models/CustomTokenRefresh';
import { DeleteAccout } from '../requests/models/DeleteAccout';
import { EditAccount } from '../requests/models/EditAccount';
import { EditUser } from '../requests/models/EditUser';
import { GetUser } from '../requests/models/GetUser';
import { LogAction } from '../requests/models/LogAction';
import { LogSource } from '../requests/models/LogSource';
import { LogType } from '../requests/models/LogType';
import { NotificationServiceUser } from '../requests/models/NotificationServiceUser';
import { OrganizationAccessUpdate } from '../requests/models/OrganizationAccessUpdate';
import { OrganizationAddUser } from '../requests/models/OrganizationAddUser';
import { OrganizationChange } from '../requests/models/OrganizationChange';
import { OrganizationShort } from '../requests/models/OrganizationShort';
import { OrganizationTeam } from '../requests/models/OrganizationTeam';
import { OrganizationTeamCreate } from '../requests/models/OrganizationTeamCreate';
import { OrganizationTeamShort } from '../requests/models/OrganizationTeamShort';
import { OrganizationUserTeam } from '../requests/models/OrganizationUserTeam';
import { ReportCreate } from '../requests/models/ReportCreate';
import { RestorePass } from '../requests/models/RestorePass';
import { RestorePassword } from '../requests/models/RestorePassword';
import { ReturnLog } from '../requests/models/ReturnLog';
import { Service } from '../requests/models/Service';
import { TokenServiceVerify } from '../requests/models/TokenServiceVerify';
import { TokenUserAuth } from '../requests/models/TokenUserAuth';
import { TokenVerify } from '../requests/models/TokenVerify';
import { UserEdit } from '../requests/models/UserEdit';
import { UserInvite } from '../requests/models/UserInvite';
import { UserInviteAccept } from '../requests/models/UserInviteAccept';
import { UserInviteApplication } from '../requests/models/UserInviteApplication';
import { UserSearch } from '../requests/models/UserSearch';
import { UserShort } from '../requests/models/UserShort';
import { AccountService } from '../requests/services/AccountService';
import { AccountsService } from '../requests/services/AccountsService';
import { ApplicationsService } from '../requests/services/ApplicationsService';
import { AuthorizationService } from '../requests/services/AuthorizationService';
import { CommonService } from '../requests/services/CommonService';
import { DashboardService } from '../requests/services/DashboardService';
import { IntegrationService } from '../requests/services/IntegrationService';
import { LogsService } from '../requests/services/LogsService';
import { OrganizationsService } from '../requests/services/OrganizationsService';
import { SupportService } from '../requests/services/SupportService';
import { UsersService } from '../requests/services/UsersService';
export type AccountServiceAccountListDefaultResponse = Awaited<
  ReturnType<typeof AccountService.accountList>
>;
export type AccountServiceAccountListQueryResult<
  TData = AccountServiceAccountListDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useAccountServiceAccountListKey = 'AccountServiceAccountList';
export const useAccountServiceAccountList = <
  TData = AccountServiceAccountListDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  {
    ordering,
    search,
    page,
    perPage,
  }: {
    ordering?: string;
    search?: string;
    page?: number;
    perPage?: number;
  },
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn' | 'initialData'>,
) =>
  useQuery<TData, TError>({
    queryKey: [
      useAccountServiceAccountListKey,
      ...(queryKey ?? [{ ordering, search, page, perPage }]),
    ],
    queryFn: () => AccountService.accountList(ordering, search, page, perPage) as TData,
    ...options,
  });
export type AccountServiceAccountCreateMutationResult = Awaited<
  ReturnType<typeof AccountService.accountCreate>
>;
export const useAccountServiceAccountCreate = <
  TData = AccountServiceAccountCreateMutationResult,
  TError = unknown,
  TContext = unknown,
>(
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      {
        data: CreateAccount;
      },
      TContext
    >,
    'mutationFn'
  >,
) =>
  useMutation<
    TData,
    TError,
    {
      data: CreateAccount;
    },
    TContext
  >({
    mutationFn: ({ data }) => AccountService.accountCreate(data) as unknown as Promise<TData>,
    ...options,
  });
export type AccountServiceAccountConfirmRestoreCreateMutationResult = Awaited<
  ReturnType<typeof AccountService.accountConfirmRestoreCreate>
>;
export const useAccountServiceAccountConfirmRestoreCreate = <
  TData = AccountServiceAccountConfirmRestoreCreateMutationResult,
  TError = unknown,
  TContext = unknown,
>(
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      {
        data: RestorePassword;
      },
      TContext
    >,
    'mutationFn'
  >,
) =>
  useMutation<
    TData,
    TError,
    {
      data: RestorePassword;
    },
    TContext
  >({
    mutationFn: ({ data }) =>
      AccountService.accountConfirmRestoreCreate(data) as unknown as Promise<TData>,
    ...options,
  });
export type AccountServiceAccountConfirmListDefaultResponse = Awaited<
  ReturnType<typeof AccountService.accountConfirmList>
>;
export type AccountServiceAccountConfirmListQueryResult<
  TData = AccountServiceAccountConfirmListDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useAccountServiceAccountConfirmListKey = 'AccountServiceAccountConfirmList';
export const useAccountServiceAccountConfirmList = <
  TData = AccountServiceAccountConfirmListDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  {
    token,
    ordering,
    search,
    page,
    perPage,
  }: {
    token: string;
    ordering?: string;
    search?: string;
    page?: number;
    perPage?: number;
  },
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn' | 'initialData'>,
) =>
  useQuery<TData, TError>({
    queryKey: [
      useAccountServiceAccountConfirmListKey,
      ...(queryKey ?? [{ token, ordering, search, page, perPage }]),
    ],
    queryFn: () =>
      AccountService.accountConfirmList(token, ordering, search, page, perPage) as TData,
    ...options,
  });
export type AccountServiceAccountServicesListDefaultResponse = Awaited<
  ReturnType<typeof AccountService.accountServicesList>
>;
export type AccountServiceAccountServicesListQueryResult<
  TData = AccountServiceAccountServicesListDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useAccountServiceAccountServicesListKey = 'AccountServiceAccountServicesList';
export const useAccountServiceAccountServicesList = <
  TData = AccountServiceAccountServicesListDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  {
    ordering,
    search,
    page,
    perPage,
  }: {
    ordering?: string;
    search?: string;
    page?: number;
    perPage?: number;
  },
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn' | 'initialData'>,
) =>
  useQuery<TData, TError>({
    queryKey: [
      useAccountServiceAccountServicesListKey,
      ...(queryKey ?? [{ ordering, search, page, perPage }]),
    ],
    queryFn: () => AccountService.accountServicesList(ordering, search, page, perPage) as TData,
    ...options,
  });
export type AccountServiceAccountUserPartialUpdateMutationResult = Awaited<
  ReturnType<typeof AccountService.accountUserPartialUpdate>
>;
export const useAccountServiceAccountUserPartialUpdate = <
  TData = AccountServiceAccountUserPartialUpdateMutationResult,
  TError = unknown,
  TContext = unknown,
>(
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      {
        data: EditUser;
      },
      TContext
    >,
    'mutationFn'
  >,
) =>
  useMutation<
    TData,
    TError,
    {
      data: EditUser;
    },
    TContext
  >({
    mutationFn: ({ data }) =>
      AccountService.accountUserPartialUpdate(data) as unknown as Promise<TData>,
    ...options,
  });
export type AccountServiceAccountPartialUpdateMutationResult = Awaited<
  ReturnType<typeof AccountService.accountPartialUpdate>
>;
export const useAccountServiceAccountPartialUpdate = <
  TData = AccountServiceAccountPartialUpdateMutationResult,
  TError = unknown,
  TContext = unknown,
>(
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      {
        id: string;
        data: EditAccount;
      },
      TContext
    >,
    'mutationFn'
  >,
) =>
  useMutation<
    TData,
    TError,
    {
      id: string;
      data: EditAccount;
    },
    TContext
  >({
    mutationFn: ({ id, data }) =>
      AccountService.accountPartialUpdate(id, data) as unknown as Promise<TData>,
    ...options,
  });
export type AccountServiceAccountDeleteMutationResult = Awaited<
  ReturnType<typeof AccountService.accountDelete>
>;
export const useAccountServiceAccountDelete = <
  TData = AccountServiceAccountDeleteMutationResult,
  TError = unknown,
  TContext = unknown,
>(
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      {
        id: string;
      },
      TContext
    >,
    'mutationFn'
  >,
) =>
  useMutation<
    TData,
    TError,
    {
      id: string;
    },
    TContext
  >({
    mutationFn: ({ id }) => AccountService.accountDelete(id) as unknown as Promise<TData>,
    ...options,
  });
export type AccountsServiceAccountsReadDefaultResponse = Awaited<
  ReturnType<typeof AccountsService.accountsRead>
>;
export type AccountsServiceAccountsReadQueryResult<
  TData = AccountsServiceAccountsReadDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useAccountsServiceAccountsReadKey = 'AccountsServiceAccountsRead';
export const useAccountsServiceAccountsRead = <
  TData = AccountsServiceAccountsReadDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  {
    id,
  }: {
    id: number;
  },
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn' | 'initialData'>,
) =>
  useQuery<TData, TError>({
    queryKey: [useAccountsServiceAccountsReadKey, ...(queryKey ?? [{ id }])],
    queryFn: () => AccountsService.accountsRead(id) as TData,
    ...options,
  });
export type AccountsServiceAccountsPartialUpdateMutationResult = Awaited<
  ReturnType<typeof AccountsService.accountsPartialUpdate>
>;
export const useAccountsServiceAccountsPartialUpdate = <
  TData = AccountsServiceAccountsPartialUpdateMutationResult,
  TError = unknown,
  TContext = unknown,
>(
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      {
        id: number;
        data: Accounts;
      },
      TContext
    >,
    'mutationFn'
  >,
) =>
  useMutation<
    TData,
    TError,
    {
      id: number;
      data: Accounts;
    },
    TContext
  >({
    mutationFn: ({ id, data }) =>
      AccountsService.accountsPartialUpdate(id, data) as unknown as Promise<TData>,
    ...options,
  });
export type ApplicationsServiceApplicationsAccessCreateMutationResult = Awaited<
  ReturnType<typeof ApplicationsService.applicationsAccessCreate>
>;
export const useApplicationsServiceApplicationsAccessCreate = <
  TData = ApplicationsServiceApplicationsAccessCreateMutationResult,
  TError = unknown,
  TContext = unknown,
>(
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      {
        organizationId: string;
        data: ApplicationAccessCreate;
      },
      TContext
    >,
    'mutationFn'
  >,
) =>
  useMutation<
    TData,
    TError,
    {
      organizationId: string;
      data: ApplicationAccessCreate;
    },
    TContext
  >({
    mutationFn: ({ organizationId, data }) =>
      ApplicationsService.applicationsAccessCreate(
        organizationId,
        data,
      ) as unknown as Promise<TData>,
    ...options,
  });
export type ApplicationsServiceApplicationsAccessReadDefaultResponse = Awaited<
  ReturnType<typeof ApplicationsService.applicationsAccessRead>
>;
export type ApplicationsServiceApplicationsAccessReadQueryResult<
  TData = ApplicationsServiceApplicationsAccessReadDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useApplicationsServiceApplicationsAccessReadKey =
  'ApplicationsServiceApplicationsAccessRead';
export const useApplicationsServiceApplicationsAccessRead = <
  TData = ApplicationsServiceApplicationsAccessReadDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  {
    organizationAccessId,
    organizationId,
  }: {
    organizationAccessId: string;
    organizationId: string;
  },
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn' | 'initialData'>,
) =>
  useQuery<TData, TError>({
    queryKey: [
      useApplicationsServiceApplicationsAccessReadKey,
      ...(queryKey ?? [{ organizationAccessId, organizationId }]),
    ],
    queryFn: () =>
      ApplicationsService.applicationsAccessRead(organizationAccessId, organizationId) as TData,
    ...options,
  });
export type ApplicationsServiceApplicationsRemoveDeleteMutationResult = Awaited<
  ReturnType<typeof ApplicationsService.applicationsRemoveDelete>
>;
export const useApplicationsServiceApplicationsRemoveDelete = <
  TData = ApplicationsServiceApplicationsRemoveDeleteMutationResult,
  TError = unknown,
  TContext = unknown,
>(
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      {
        appToken: string;
      },
      TContext
    >,
    'mutationFn'
  >,
) =>
  useMutation<
    TData,
    TError,
    {
      appToken: string;
    },
    TContext
  >({
    mutationFn: ({ appToken }) =>
      ApplicationsService.applicationsRemoveDelete(appToken) as unknown as Promise<TData>,
    ...options,
  });
export type ApplicationsServiceApplicationsRepositoryReadDefaultResponse = Awaited<
  ReturnType<typeof ApplicationsService.applicationsRepositoryRead>
>;
export type ApplicationsServiceApplicationsRepositoryReadQueryResult<
  TData = ApplicationsServiceApplicationsRepositoryReadDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useApplicationsServiceApplicationsRepositoryReadKey =
  'ApplicationsServiceApplicationsRepositoryRead';
export const useApplicationsServiceApplicationsRepositoryRead = <
  TData = ApplicationsServiceApplicationsRepositoryReadDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  {
    organizationId,
  }: {
    organizationId: string;
  },
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn' | 'initialData'>,
) =>
  useQuery<TData, TError>({
    queryKey: [
      useApplicationsServiceApplicationsRepositoryReadKey,
      ...(queryKey ?? [{ organizationId }]),
    ],
    queryFn: () => ApplicationsService.applicationsRepositoryRead(organizationId) as TData,
    ...options,
  });
export type ApplicationsServiceApplicationsTeamListListDefaultResponse = Awaited<
  ReturnType<typeof ApplicationsService.applicationsTeamListList>
>;
export type ApplicationsServiceApplicationsTeamListListQueryResult<
  TData = ApplicationsServiceApplicationsTeamListListDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useApplicationsServiceApplicationsTeamListListKey =
  'ApplicationsServiceApplicationsTeamListList';
export const useApplicationsServiceApplicationsTeamListList = <
  TData = ApplicationsServiceApplicationsTeamListListDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  {
    appToken,
    ordering,
    search,
    page,
    pageSize,
  }: {
    appToken: string;
    ordering?: string;
    search?: string;
    page?: number;
    pageSize?: number;
  },
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn' | 'initialData'>,
) =>
  useQuery<TData, TError>({
    queryKey: [
      useApplicationsServiceApplicationsTeamListListKey,
      ...(queryKey ?? [{ appToken, ordering, search, page, pageSize }]),
    ],
    queryFn: () =>
      ApplicationsService.applicationsTeamListList(
        appToken,
        ordering,
        search,
        page,
        pageSize,
      ) as TData,
    ...options,
  });
export type ApplicationsServiceApplicationsUserCanAccessListDefaultResponse = Awaited<
  ReturnType<typeof ApplicationsService.applicationsUserCanAccessList>
>;
export type ApplicationsServiceApplicationsUserCanAccessListQueryResult<
  TData = ApplicationsServiceApplicationsUserCanAccessListDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useApplicationsServiceApplicationsUserCanAccessListKey =
  'ApplicationsServiceApplicationsUserCanAccessList';
export const useApplicationsServiceApplicationsUserCanAccessList = <
  TData = ApplicationsServiceApplicationsUserCanAccessListDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  {
    appToken,
    ordering,
    search,
    page,
    perPage,
  }: {
    appToken: string;
    ordering?: string;
    search?: string;
    page?: number;
    perPage?: number;
  },
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn' | 'initialData'>,
) =>
  useQuery<TData, TError>({
    queryKey: [
      useApplicationsServiceApplicationsUserCanAccessListKey,
      ...(queryKey ?? [{ appToken, ordering, search, page, perPage }]),
    ],
    queryFn: () =>
      ApplicationsService.applicationsUserCanAccessList(
        appToken,
        ordering,
        search,
        page,
        perPage,
      ) as TData,
    ...options,
  });
export type ApplicationsServiceApplicationsUserDetailsListDefaultResponse = Awaited<
  ReturnType<typeof ApplicationsService.applicationsUserDetailsList>
>;
export type ApplicationsServiceApplicationsUserDetailsListQueryResult<
  TData = ApplicationsServiceApplicationsUserDetailsListDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useApplicationsServiceApplicationsUserDetailsListKey =
  'ApplicationsServiceApplicationsUserDetailsList';
export const useApplicationsServiceApplicationsUserDetailsList = <
  TData = ApplicationsServiceApplicationsUserDetailsListDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  {
    appToken,
    ordering,
    search,
    page,
    perPage,
  }: {
    appToken: string;
    ordering?: string;
    search?: string;
    page?: number;
    perPage?: number;
  },
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn' | 'initialData'>,
) =>
  useQuery<TData, TError>({
    queryKey: [
      useApplicationsServiceApplicationsUserDetailsListKey,
      ...(queryKey ?? [{ appToken, ordering, search, page, perPage }]),
    ],
    queryFn: () =>
      ApplicationsService.applicationsUserDetailsList(
        appToken,
        ordering,
        search,
        page,
        perPage,
      ) as TData,
    ...options,
  });
export type ApplicationsServiceApplicationsUserListListDefaultResponse = Awaited<
  ReturnType<typeof ApplicationsService.applicationsUserListList>
>;
export type ApplicationsServiceApplicationsUserListListQueryResult<
  TData = ApplicationsServiceApplicationsUserListListDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useApplicationsServiceApplicationsUserListListKey =
  'ApplicationsServiceApplicationsUserListList';
export const useApplicationsServiceApplicationsUserListList = <
  TData = ApplicationsServiceApplicationsUserListListDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  {
    appToken,
    search,
    page,
    pageSize,
  }: {
    appToken: string;
    search?: string;
    page?: number;
    pageSize?: number;
  },
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn' | 'initialData'>,
) =>
  useQuery<TData, TError>({
    queryKey: [
      useApplicationsServiceApplicationsUserListListKey,
      ...(queryKey ?? [{ appToken, search, page, pageSize }]),
    ],
    queryFn: () =>
      ApplicationsService.applicationsUserListList(appToken, search, page, pageSize) as TData,
    ...options,
  });
export type ApplicationsServiceApplicationsValidateReadDefaultResponse = Awaited<
  ReturnType<typeof ApplicationsService.applicationsValidateRead>
>;
export type ApplicationsServiceApplicationsValidateReadQueryResult<
  TData = ApplicationsServiceApplicationsValidateReadDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useApplicationsServiceApplicationsValidateReadKey =
  'ApplicationsServiceApplicationsValidateRead';
export const useApplicationsServiceApplicationsValidateRead = <
  TData = ApplicationsServiceApplicationsValidateReadDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  {
    appToken,
  }: {
    appToken: string;
  },
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn' | 'initialData'>,
) =>
  useQuery<TData, TError>({
    queryKey: [useApplicationsServiceApplicationsValidateReadKey, ...(queryKey ?? [{ appToken }])],
    queryFn: () => ApplicationsService.applicationsValidateRead(appToken) as TData,
    ...options,
  });
export type ApplicationsServiceApplicationsReadDefaultResponse = Awaited<
  ReturnType<typeof ApplicationsService.applicationsRead>
>;
export type ApplicationsServiceApplicationsReadQueryResult<
  TData = ApplicationsServiceApplicationsReadDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useApplicationsServiceApplicationsReadKey = 'ApplicationsServiceApplicationsRead';
export const useApplicationsServiceApplicationsRead = <
  TData = ApplicationsServiceApplicationsReadDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  {
    organizationId,
    orderBy,
    orderDirection,
  }: {
    organizationId: string;
    orderBy?: 'name' | 'date_created';
    orderDirection?: 'asc' | 'desc';
  },
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn' | 'initialData'>,
) =>
  useQuery<TData, TError>({
    queryKey: [
      useApplicationsServiceApplicationsReadKey,
      ...(queryKey ?? [{ organizationId, orderBy, orderDirection }]),
    ],
    queryFn: () =>
      ApplicationsService.applicationsRead(organizationId, orderBy, orderDirection) as TData,
    ...options,
  });
export type ApplicationsServiceApplicationsAllListDefaultResponse = Awaited<
  ReturnType<typeof ApplicationsService.applicationsAllList>
>;
export type ApplicationsServiceApplicationsAllListQueryResult<
  TData = ApplicationsServiceApplicationsAllListDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useApplicationsServiceApplicationsAllListKey =
  'ApplicationsServiceApplicationsAllList';
export const useApplicationsServiceApplicationsAllList = <
  TData = ApplicationsServiceApplicationsAllListDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  {
    organizationId,
    ordering,
    search,
    page,
    perPage,
  }: {
    organizationId: string;
    ordering?: string;
    search?: string;
    page?: number;
    perPage?: number;
  },
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn' | 'initialData'>,
) =>
  useQuery<TData, TError>({
    queryKey: [
      useApplicationsServiceApplicationsAllListKey,
      ...(queryKey ?? [{ organizationId, ordering, search, page, perPage }]),
    ],
    queryFn: () =>
      ApplicationsService.applicationsAllList(
        organizationId,
        ordering,
        search,
        page,
        perPage,
      ) as TData,
    ...options,
  });
export type AuthorizationServiceAuthorizationTokenCreateMutationResult = Awaited<
  ReturnType<typeof AuthorizationService.authorizationTokenCreate>
>;
export const useAuthorizationServiceAuthorizationTokenCreate = <
  TData = AuthorizationServiceAuthorizationTokenCreateMutationResult,
  TError = unknown,
  TContext = unknown,
>(
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      {
        data: TokenUserAuth;
      },
      TContext
    >,
    'mutationFn'
  >,
) =>
  useMutation<
    TData,
    TError,
    {
      data: TokenUserAuth;
    },
    TContext
  >({
    mutationFn: ({ data }) =>
      AuthorizationService.authorizationTokenCreate(data) as unknown as Promise<TData>,
    ...options,
  });
export type AuthorizationServiceAuthorizationTokenCookieListDefaultResponse = Awaited<
  ReturnType<typeof AuthorizationService.authorizationTokenCookieList>
>;
export type AuthorizationServiceAuthorizationTokenCookieListQueryResult<
  TData = AuthorizationServiceAuthorizationTokenCookieListDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useAuthorizationServiceAuthorizationTokenCookieListKey =
  'AuthorizationServiceAuthorizationTokenCookieList';
export const useAuthorizationServiceAuthorizationTokenCookieList = <
  TData = AuthorizationServiceAuthorizationTokenCookieListDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  {
    ordering,
    search,
    page,
    perPage,
  }: {
    ordering?: string;
    search?: string;
    page?: number;
    perPage?: number;
  },
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn' | 'initialData'>,
) =>
  useQuery<TData, TError>({
    queryKey: [
      useAuthorizationServiceAuthorizationTokenCookieListKey,
      ...(queryKey ?? [{ ordering, search, page, perPage }]),
    ],
    queryFn: () =>
      AuthorizationService.authorizationTokenCookieList(ordering, search, page, perPage) as TData,
    ...options,
  });
export type AuthorizationServiceAuthorizationTokenLogoutListDefaultResponse = Awaited<
  ReturnType<typeof AuthorizationService.authorizationTokenLogoutList>
>;
export type AuthorizationServiceAuthorizationTokenLogoutListQueryResult<
  TData = AuthorizationServiceAuthorizationTokenLogoutListDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useAuthorizationServiceAuthorizationTokenLogoutListKey =
  'AuthorizationServiceAuthorizationTokenLogoutList';
export const useAuthorizationServiceAuthorizationTokenLogoutList = <
  TData = AuthorizationServiceAuthorizationTokenLogoutListDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn' | 'initialData'>,
) =>
  useQuery<TData, TError>({
    queryKey: [useAuthorizationServiceAuthorizationTokenLogoutListKey, ...(queryKey ?? [])],
    queryFn: () => AuthorizationService.authorizationTokenLogoutList() as TData,
    ...options,
  });
export type AuthorizationServiceAuthorizationTokenRefreshCreateMutationResult = Awaited<
  ReturnType<typeof AuthorizationService.authorizationTokenRefreshCreate>
>;
export const useAuthorizationServiceAuthorizationTokenRefreshCreate = <
  TData = AuthorizationServiceAuthorizationTokenRefreshCreateMutationResult,
  TError = unknown,
  TContext = unknown,
>(
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      {
        data: CustomTokenRefresh;
      },
      TContext
    >,
    'mutationFn'
  >,
) =>
  useMutation<
    TData,
    TError,
    {
      data: CustomTokenRefresh;
    },
    TContext
  >({
    mutationFn: ({ data }) =>
      AuthorizationService.authorizationTokenRefreshCreate(data) as unknown as Promise<TData>,
    ...options,
  });
export type AuthorizationServiceAuthorizationTokenServiceVerifyCreateMutationResult = Awaited<
  ReturnType<typeof AuthorizationService.authorizationTokenServiceVerifyCreate>
>;
export const useAuthorizationServiceAuthorizationTokenServiceVerifyCreate = <
  TData = AuthorizationServiceAuthorizationTokenServiceVerifyCreateMutationResult,
  TError = unknown,
  TContext = unknown,
>(
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      {
        data: TokenServiceVerify;
      },
      TContext
    >,
    'mutationFn'
  >,
) =>
  useMutation<
    TData,
    TError,
    {
      data: TokenServiceVerify;
    },
    TContext
  >({
    mutationFn: ({ data }) =>
      AuthorizationService.authorizationTokenServiceVerifyCreate(data) as unknown as Promise<TData>,
    ...options,
  });
export type AuthorizationServiceAuthorizationTokenVerifyCreateMutationResult = Awaited<
  ReturnType<typeof AuthorizationService.authorizationTokenVerifyCreate>
>;
export const useAuthorizationServiceAuthorizationTokenVerifyCreate = <
  TData = AuthorizationServiceAuthorizationTokenVerifyCreateMutationResult,
  TError = unknown,
  TContext = unknown,
>(
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      {
        data: TokenVerify;
      },
      TContext
    >,
    'mutationFn'
  >,
) =>
  useMutation<
    TData,
    TError,
    {
      data: TokenVerify;
    },
    TContext
  >({
    mutationFn: ({ data }) =>
      AuthorizationService.authorizationTokenVerifyCreate(data) as unknown as Promise<TData>,
    ...options,
  });
export type AuthorizationServiceAuthorizationUserDeleteAccountCreateMutationResult = Awaited<
  ReturnType<typeof AuthorizationService.authorizationUserDeleteAccountCreate>
>;
export const useAuthorizationServiceAuthorizationUserDeleteAccountCreate = <
  TData = AuthorizationServiceAuthorizationUserDeleteAccountCreateMutationResult,
  TError = unknown,
  TContext = unknown,
>(
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      {
        data: DeleteAccout;
      },
      TContext
    >,
    'mutationFn'
  >,
) =>
  useMutation<
    TData,
    TError,
    {
      data: DeleteAccout;
    },
    TContext
  >({
    mutationFn: ({ data }) =>
      AuthorizationService.authorizationUserDeleteAccountCreate(data) as unknown as Promise<TData>,
    ...options,
  });
export type AuthorizationServiceAuthorizationUserRestoreCreateMutationResult = Awaited<
  ReturnType<typeof AuthorizationService.authorizationUserRestoreCreate>
>;
export const useAuthorizationServiceAuthorizationUserRestoreCreate = <
  TData = AuthorizationServiceAuthorizationUserRestoreCreateMutationResult,
  TError = unknown,
  TContext = unknown,
>(
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      {
        data: RestorePass;
      },
      TContext
    >,
    'mutationFn'
  >,
) =>
  useMutation<
    TData,
    TError,
    {
      data: RestorePass;
    },
    TContext
  >({
    mutationFn: ({ data }) =>
      AuthorizationService.authorizationUserRestoreCreate(data) as unknown as Promise<TData>,
    ...options,
  });
export type CommonServiceCommonHealthListDefaultResponse = Awaited<
  ReturnType<typeof CommonService.commonHealthList>
>;
export type CommonServiceCommonHealthListQueryResult<
  TData = CommonServiceCommonHealthListDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useCommonServiceCommonHealthListKey = 'CommonServiceCommonHealthList';
export const useCommonServiceCommonHealthList = <
  TData = CommonServiceCommonHealthListDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  {
    ordering,
    search,
    page,
    perPage,
  }: {
    ordering?: string;
    search?: string;
    page?: number;
    perPage?: number;
  },
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn' | 'initialData'>,
) =>
  useQuery<TData, TError>({
    queryKey: [
      useCommonServiceCommonHealthListKey,
      ...(queryKey ?? [{ ordering, search, page, perPage }]),
    ],
    queryFn: () => CommonService.commonHealthList(ordering, search, page, perPage) as TData,
    ...options,
  });
export type CommonServiceCommonProtectedListDefaultResponse = Awaited<
  ReturnType<typeof CommonService.commonProtectedList>
>;
export type CommonServiceCommonProtectedListQueryResult<
  TData = CommonServiceCommonProtectedListDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useCommonServiceCommonProtectedListKey = 'CommonServiceCommonProtectedList';
export const useCommonServiceCommonProtectedList = <
  TData = CommonServiceCommonProtectedListDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  {
    ordering,
    search,
    page,
    perPage,
  }: {
    ordering?: string;
    search?: string;
    page?: number;
    perPage?: number;
  },
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn' | 'initialData'>,
) =>
  useQuery<TData, TError>({
    queryKey: [
      useCommonServiceCommonProtectedListKey,
      ...(queryKey ?? [{ ordering, search, page, perPage }]),
    ],
    queryFn: () => CommonService.commonProtectedList(ordering, search, page, perPage) as TData,
    ...options,
  });
export type DashboardServiceDashboardWidgetsReadDefaultResponse = Awaited<
  ReturnType<typeof DashboardService.dashboardWidgetsRead>
>;
export type DashboardServiceDashboardWidgetsReadQueryResult<
  TData = DashboardServiceDashboardWidgetsReadDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useDashboardServiceDashboardWidgetsReadKey = 'DashboardServiceDashboardWidgetsRead';
export const useDashboardServiceDashboardWidgetsRead = <
  TData = DashboardServiceDashboardWidgetsReadDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  {
    organizationId,
  }: {
    organizationId: string;
  },
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn' | 'initialData'>,
) =>
  useQuery<TData, TError>({
    queryKey: [useDashboardServiceDashboardWidgetsReadKey, ...(queryKey ?? [{ organizationId }])],
    queryFn: () => DashboardService.dashboardWidgetsRead(organizationId) as TData,
    ...options,
  });
export type IntegrationServiceIntegrationUserDetailCreateMutationResult = Awaited<
  ReturnType<typeof IntegrationService.integrationUserDetailCreate>
>;
export const useIntegrationServiceIntegrationUserDetailCreate = <
  TData = IntegrationServiceIntegrationUserDetailCreateMutationResult,
  TError = unknown,
  TContext = unknown,
>(
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      {
        data: GetUser;
      },
      TContext
    >,
    'mutationFn'
  >,
) =>
  useMutation<
    TData,
    TError,
    {
      data: GetUser;
    },
    TContext
  >({
    mutationFn: ({ data }) =>
      IntegrationService.integrationUserDetailCreate(data) as unknown as Promise<TData>,
    ...options,
  });
export type LogsServiceLogsListDefaultResponse = Awaited<ReturnType<typeof LogsService.logsList>>;
export type LogsServiceLogsListQueryResult<
  TData = LogsServiceLogsListDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useLogsServiceLogsListKey = 'LogsServiceLogsList';
export const useLogsServiceLogsList = <
  TData = LogsServiceLogsListDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  {
    ordering,
    userId,
    userIp,
    source,
    action,
    logType,
    instanceId,
    search,
    page,
    perPage,
  }: {
    ordering?: string;
    userId?: number;
    userIp?: string;
    source?: string;
    action?: string;
    logType?: string;
    instanceId?: string;
    search?: string;
    page?: number;
    perPage?: number;
  },
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn' | 'initialData'>,
) =>
  useQuery<TData, TError>({
    queryKey: [
      useLogsServiceLogsListKey,
      ...(queryKey ?? [
        { ordering, userId, userIp, source, action, logType, instanceId, search, page, perPage },
      ]),
    ],
    queryFn: () =>
      LogsService.logsList(
        ordering,
        userId,
        userIp,
        source,
        action,
        logType,
        instanceId,
        search,
        page,
        perPage,
      ) as TData,
    ...options,
  });
export type LogsServiceLogsActionsListDefaultResponse = Awaited<
  ReturnType<typeof LogsService.logsActionsList>
>;
export type LogsServiceLogsActionsListQueryResult<
  TData = LogsServiceLogsActionsListDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useLogsServiceLogsActionsListKey = 'LogsServiceLogsActionsList';
export const useLogsServiceLogsActionsList = <
  TData = LogsServiceLogsActionsListDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  {
    ordering,
    search,
    page,
    perPage,
  }: {
    ordering?: string;
    search?: string;
    page?: number;
    perPage?: number;
  },
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn' | 'initialData'>,
) =>
  useQuery<TData, TError>({
    queryKey: [
      useLogsServiceLogsActionsListKey,
      ...(queryKey ?? [{ ordering, search, page, perPage }]),
    ],
    queryFn: () => LogsService.logsActionsList(ordering, search, page, perPage) as TData,
    ...options,
  });
export type LogsServiceLogsSourcesListDefaultResponse = Awaited<
  ReturnType<typeof LogsService.logsSourcesList>
>;
export type LogsServiceLogsSourcesListQueryResult<
  TData = LogsServiceLogsSourcesListDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useLogsServiceLogsSourcesListKey = 'LogsServiceLogsSourcesList';
export const useLogsServiceLogsSourcesList = <
  TData = LogsServiceLogsSourcesListDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  {
    ordering,
    search,
    page,
    perPage,
  }: {
    ordering?: string;
    search?: string;
    page?: number;
    perPage?: number;
  },
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn' | 'initialData'>,
) =>
  useQuery<TData, TError>({
    queryKey: [
      useLogsServiceLogsSourcesListKey,
      ...(queryKey ?? [{ ordering, search, page, perPage }]),
    ],
    queryFn: () => LogsService.logsSourcesList(ordering, search, page, perPage) as TData,
    ...options,
  });
export type LogsServiceLogsTypesListDefaultResponse = Awaited<
  ReturnType<typeof LogsService.logsTypesList>
>;
export type LogsServiceLogsTypesListQueryResult<
  TData = LogsServiceLogsTypesListDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useLogsServiceLogsTypesListKey = 'LogsServiceLogsTypesList';
export const useLogsServiceLogsTypesList = <
  TData = LogsServiceLogsTypesListDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  {
    ordering,
    search,
    page,
    perPage,
  }: {
    ordering?: string;
    search?: string;
    page?: number;
    perPage?: number;
  },
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn' | 'initialData'>,
) =>
  useQuery<TData, TError>({
    queryKey: [
      useLogsServiceLogsTypesListKey,
      ...(queryKey ?? [{ ordering, search, page, perPage }]),
    ],
    queryFn: () => LogsService.logsTypesList(ordering, search, page, perPage) as TData,
    ...options,
  });
export type OrganizationsServiceOrganizationsChangeUpdateMutationResult = Awaited<
  ReturnType<typeof OrganizationsService.organizationsChangeUpdate>
>;
export const useOrganizationsServiceOrganizationsChangeUpdate = <
  TData = OrganizationsServiceOrganizationsChangeUpdateMutationResult,
  TError = unknown,
  TContext = unknown,
>(
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      {
        organizationId: string;
        data: OrganizationChange;
      },
      TContext
    >,
    'mutationFn'
  >,
) =>
  useMutation<
    TData,
    TError,
    {
      organizationId: string;
      data: OrganizationChange;
    },
    TContext
  >({
    mutationFn: ({ organizationId, data }) =>
      OrganizationsService.organizationsChangeUpdate(
        organizationId,
        data,
      ) as unknown as Promise<TData>,
    ...options,
  });
export type OrganizationsServiceOrganizationsDomainNameExistsReadDefaultResponse = Awaited<
  ReturnType<typeof OrganizationsService.organizationsDomainNameExistsRead>
>;
export type OrganizationsServiceOrganizationsDomainNameExistsReadQueryResult<
  TData = OrganizationsServiceOrganizationsDomainNameExistsReadDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useOrganizationsServiceOrganizationsDomainNameExistsReadKey =
  'OrganizationsServiceOrganizationsDomainNameExistsRead';
export const useOrganizationsServiceOrganizationsDomainNameExistsRead = <
  TData = OrganizationsServiceOrganizationsDomainNameExistsReadDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  {
    domainName,
  }: {
    domainName: string;
  },
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn' | 'initialData'>,
) =>
  useQuery<TData, TError>({
    queryKey: [
      useOrganizationsServiceOrganizationsDomainNameExistsReadKey,
      ...(queryKey ?? [{ domainName }]),
    ],
    queryFn: () => OrganizationsService.organizationsDomainNameExistsRead(domainName) as TData,
    ...options,
  });
export type OrganizationsServiceOrganizationsInfoReadDefaultResponse = Awaited<
  ReturnType<typeof OrganizationsService.organizationsInfoRead>
>;
export type OrganizationsServiceOrganizationsInfoReadQueryResult<
  TData = OrganizationsServiceOrganizationsInfoReadDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useOrganizationsServiceOrganizationsInfoReadKey =
  'OrganizationsServiceOrganizationsInfoRead';
export const useOrganizationsServiceOrganizationsInfoRead = <
  TData = OrganizationsServiceOrganizationsInfoReadDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  {
    domainName,
  }: {
    domainName: string;
  },
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn' | 'initialData'>,
) =>
  useQuery<TData, TError>({
    queryKey: [useOrganizationsServiceOrganizationsInfoReadKey, ...(queryKey ?? [{ domainName }])],
    queryFn: () => OrganizationsService.organizationsInfoRead(domainName) as TData,
    ...options,
  });
export type OrganizationsServiceOrganizationsTeamReadDefaultResponse = Awaited<
  ReturnType<typeof OrganizationsService.organizationsTeamRead>
>;
export type OrganizationsServiceOrganizationsTeamReadQueryResult<
  TData = OrganizationsServiceOrganizationsTeamReadDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useOrganizationsServiceOrganizationsTeamReadKey =
  'OrganizationsServiceOrganizationsTeamRead';
export const useOrganizationsServiceOrganizationsTeamRead = <
  TData = OrganizationsServiceOrganizationsTeamReadDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  {
    id,
  }: {
    id: number;
  },
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn' | 'initialData'>,
) =>
  useQuery<TData, TError>({
    queryKey: [useOrganizationsServiceOrganizationsTeamReadKey, ...(queryKey ?? [{ id }])],
    queryFn: () => OrganizationsService.organizationsTeamRead(id) as TData,
    ...options,
  });
export type OrganizationsServiceOrganizationsTeamUpdateMutationResult = Awaited<
  ReturnType<typeof OrganizationsService.organizationsTeamUpdate>
>;
export const useOrganizationsServiceOrganizationsTeamUpdate = <
  TData = OrganizationsServiceOrganizationsTeamUpdateMutationResult,
  TError = unknown,
  TContext = unknown,
>(
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      {
        id: number;
        data: OrganizationTeam;
      },
      TContext
    >,
    'mutationFn'
  >,
) =>
  useMutation<
    TData,
    TError,
    {
      id: number;
      data: OrganizationTeam;
    },
    TContext
  >({
    mutationFn: ({ id, data }) =>
      OrganizationsService.organizationsTeamUpdate(id, data) as unknown as Promise<TData>,
    ...options,
  });
export type OrganizationsServiceOrganizationsTeamDeleteMutationResult = Awaited<
  ReturnType<typeof OrganizationsService.organizationsTeamDelete>
>;
export const useOrganizationsServiceOrganizationsTeamDelete = <
  TData = OrganizationsServiceOrganizationsTeamDeleteMutationResult,
  TError = unknown,
  TContext = unknown,
>(
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      {
        id: number;
      },
      TContext
    >,
    'mutationFn'
  >,
) =>
  useMutation<
    TData,
    TError,
    {
      id: number;
    },
    TContext
  >({
    mutationFn: ({ id }) =>
      OrganizationsService.organizationsTeamDelete(id) as unknown as Promise<TData>,
    ...options,
  });
export type OrganizationsServiceOrganizationsTeamsCreateCreateMutationResult = Awaited<
  ReturnType<typeof OrganizationsService.organizationsTeamsCreateCreate>
>;
export const useOrganizationsServiceOrganizationsTeamsCreateCreate = <
  TData = OrganizationsServiceOrganizationsTeamsCreateCreateMutationResult,
  TError = unknown,
  TContext = unknown,
>(
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      {
        organizationId: string;
        data: OrganizationTeamCreate;
      },
      TContext
    >,
    'mutationFn'
  >,
) =>
  useMutation<
    TData,
    TError,
    {
      organizationId: string;
      data: OrganizationTeamCreate;
    },
    TContext
  >({
    mutationFn: ({ organizationId, data }) =>
      OrganizationsService.organizationsTeamsCreateCreate(
        organizationId,
        data,
      ) as unknown as Promise<TData>,
    ...options,
  });
export type OrganizationsServiceOrganizationsTeamsListDefaultResponse = Awaited<
  ReturnType<typeof OrganizationsService.organizationsTeamsList>
>;
export type OrganizationsServiceOrganizationsTeamsListQueryResult<
  TData = OrganizationsServiceOrganizationsTeamsListDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useOrganizationsServiceOrganizationsTeamsListKey =
  'OrganizationsServiceOrganizationsTeamsList';
export const useOrganizationsServiceOrganizationsTeamsList = <
  TData = OrganizationsServiceOrganizationsTeamsListDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  {
    organizationId,
    ordering,
    search,
    page,
    perPage,
  }: {
    organizationId: string;
    ordering?: string;
    search?: string;
    page?: number;
    perPage?: number;
  },
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn' | 'initialData'>,
) =>
  useQuery<TData, TError>({
    queryKey: [
      useOrganizationsServiceOrganizationsTeamsListKey,
      ...(queryKey ?? [{ organizationId, ordering, search, page, perPage }]),
    ],
    queryFn: () =>
      OrganizationsService.organizationsTeamsList(
        organizationId,
        ordering,
        search,
        page,
        perPage,
      ) as TData,
    ...options,
  });
export type OrganizationsServiceOrganizationsUserTeamCreateMutationResult = Awaited<
  ReturnType<typeof OrganizationsService.organizationsUserTeamCreate>
>;
export const useOrganizationsServiceOrganizationsUserTeamCreate = <
  TData = OrganizationsServiceOrganizationsUserTeamCreateMutationResult,
  TError = unknown,
  TContext = unknown,
>(
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      {
        data: OrganizationUserTeam;
      },
      TContext
    >,
    'mutationFn'
  >,
) =>
  useMutation<
    TData,
    TError,
    {
      data: OrganizationUserTeam;
    },
    TContext
  >({
    mutationFn: ({ data }) =>
      OrganizationsService.organizationsUserTeamCreate(data) as unknown as Promise<TData>,
    ...options,
  });
export type OrganizationsServiceOrganizationsUserTeamDeleteMutationResult = Awaited<
  ReturnType<typeof OrganizationsService.organizationsUserTeamDelete>
>;
export const useOrganizationsServiceOrganizationsUserTeamDelete = <
  TData = OrganizationsServiceOrganizationsUserTeamDeleteMutationResult,
  TError = unknown,
  TContext = unknown,
>(
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      {
        teamId: string;
        userId: string;
      },
      TContext
    >,
    'mutationFn'
  >,
) =>
  useMutation<
    TData,
    TError,
    {
      teamId: string;
      userId: string;
    },
    TContext
  >({
    mutationFn: ({ teamId, userId }) =>
      OrganizationsService.organizationsUserTeamDelete(teamId, userId) as unknown as Promise<TData>,
    ...options,
  });
export type OrganizationsServiceOrganizationsUserReadDefaultResponse = Awaited<
  ReturnType<typeof OrganizationsService.organizationsUserRead>
>;
export type OrganizationsServiceOrganizationsUserReadQueryResult<
  TData = OrganizationsServiceOrganizationsUserReadDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useOrganizationsServiceOrganizationsUserReadKey =
  'OrganizationsServiceOrganizationsUserRead';
export const useOrganizationsServiceOrganizationsUserRead = <
  TData = OrganizationsServiceOrganizationsUserReadDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  {
    id,
  }: {
    id: number;
  },
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn' | 'initialData'>,
) =>
  useQuery<TData, TError>({
    queryKey: [useOrganizationsServiceOrganizationsUserReadKey, ...(queryKey ?? [{ id }])],
    queryFn: () => OrganizationsService.organizationsUserRead(id) as TData,
    ...options,
  });
export type OrganizationsServiceOrganizationsUserUpdateMutationResult = Awaited<
  ReturnType<typeof OrganizationsService.organizationsUserUpdate>
>;
export const useOrganizationsServiceOrganizationsUserUpdate = <
  TData = OrganizationsServiceOrganizationsUserUpdateMutationResult,
  TError = unknown,
  TContext = unknown,
>(
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      {
        id: number;
        data: OrganizationAccessUpdate;
      },
      TContext
    >,
    'mutationFn'
  >,
) =>
  useMutation<
    TData,
    TError,
    {
      id: number;
      data: OrganizationAccessUpdate;
    },
    TContext
  >({
    mutationFn: ({ id, data }) =>
      OrganizationsService.organizationsUserUpdate(id, data) as unknown as Promise<TData>,
    ...options,
  });
export type OrganizationsServiceOrganizationsUserDeleteMutationResult = Awaited<
  ReturnType<typeof OrganizationsService.organizationsUserDelete>
>;
export const useOrganizationsServiceOrganizationsUserDelete = <
  TData = OrganizationsServiceOrganizationsUserDeleteMutationResult,
  TError = unknown,
  TContext = unknown,
>(
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      {
        id: number;
      },
      TContext
    >,
    'mutationFn'
  >,
) =>
  useMutation<
    TData,
    TError,
    {
      id: number;
    },
    TContext
  >({
    mutationFn: ({ id }) =>
      OrganizationsService.organizationsUserDelete(id) as unknown as Promise<TData>,
    ...options,
  });
export type OrganizationsServiceOrganizationsUserEditPartialUpdateMutationResult = Awaited<
  ReturnType<typeof OrganizationsService.organizationsUserEditPartialUpdate>
>;
export const useOrganizationsServiceOrganizationsUserEditPartialUpdate = <
  TData = OrganizationsServiceOrganizationsUserEditPartialUpdateMutationResult,
  TError = unknown,
  TContext = unknown,
>(
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      {
        ssoId: number;
        data: UserEdit;
      },
      TContext
    >,
    'mutationFn'
  >,
) =>
  useMutation<
    TData,
    TError,
    {
      ssoId: number;
      data: UserEdit;
    },
    TContext
  >({
    mutationFn: ({ ssoId, data }) =>
      OrganizationsService.organizationsUserEditPartialUpdate(
        ssoId,
        data,
      ) as unknown as Promise<TData>,
    ...options,
  });
export type OrganizationsServiceOrganizationsUsersCreateCreateMutationResult = Awaited<
  ReturnType<typeof OrganizationsService.organizationsUsersCreateCreate>
>;
export const useOrganizationsServiceOrganizationsUsersCreateCreate = <
  TData = OrganizationsServiceOrganizationsUsersCreateCreateMutationResult,
  TError = unknown,
  TContext = unknown,
>(
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      {
        organizationId: string;
        data: OrganizationAddUser;
      },
      TContext
    >,
    'mutationFn'
  >,
) =>
  useMutation<
    TData,
    TError,
    {
      organizationId: string;
      data: OrganizationAddUser;
    },
    TContext
  >({
    mutationFn: ({ organizationId, data }) =>
      OrganizationsService.organizationsUsersCreateCreate(
        organizationId,
        data,
      ) as unknown as Promise<TData>,
    ...options,
  });
export type OrganizationsServiceOrganizationsUsersReadDefaultResponse = Awaited<
  ReturnType<typeof OrganizationsService.organizationsUsersRead>
>;
export type OrganizationsServiceOrganizationsUsersReadQueryResult<
  TData = OrganizationsServiceOrganizationsUsersReadDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useOrganizationsServiceOrganizationsUsersReadKey =
  'OrganizationsServiceOrganizationsUsersRead';
export const useOrganizationsServiceOrganizationsUsersRead = <
  TData = OrganizationsServiceOrganizationsUsersReadDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  {
    organizationId,
    page,
    perPage,
    search,
    teamId,
    orderBy,
    orderDirection,
    status,
  }: {
    organizationId: string;
    page?: number;
    perPage?: number;
    search?: string;
    teamId?: number;
    orderBy?: 'first_name' | 'date_joined';
    orderDirection?: 'asc' | 'desc';
    status?: 'online' | 'offline' | 'all';
  },
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn' | 'initialData'>,
) =>
  useQuery<TData, TError>({
    queryKey: [
      useOrganizationsServiceOrganizationsUsersReadKey,
      ...(queryKey ?? [
        { organizationId, page, perPage, search, teamId, orderBy, orderDirection, status },
      ]),
    ],
    queryFn: () =>
      OrganizationsService.organizationsUsersRead(
        organizationId,
        page,
        perPage,
        search,
        teamId,
        orderBy,
        orderDirection,
        status,
      ) as TData,
    ...options,
  });
export type OrganizationsServiceOrganizationsReadDefaultResponse = Awaited<
  ReturnType<typeof OrganizationsService.organizationsRead>
>;
export type OrganizationsServiceOrganizationsReadQueryResult<
  TData = OrganizationsServiceOrganizationsReadDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useOrganizationsServiceOrganizationsReadKey = 'OrganizationsServiceOrganizationsRead';
export const useOrganizationsServiceOrganizationsRead = <
  TData = OrganizationsServiceOrganizationsReadDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  {
    accountId,
  }: {
    accountId: string;
  },
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn' | 'initialData'>,
) =>
  useQuery<TData, TError>({
    queryKey: [useOrganizationsServiceOrganizationsReadKey, ...(queryKey ?? [{ accountId }])],
    queryFn: () => OrganizationsService.organizationsRead(accountId) as TData,
    ...options,
  });
export type SupportServiceSupportReportCreateMutationResult = Awaited<
  ReturnType<typeof SupportService.supportReportCreate>
>;
export const useSupportServiceSupportReportCreate = <
  TData = SupportServiceSupportReportCreateMutationResult,
  TError = unknown,
  TContext = unknown,
>(
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      {
        data: ReportCreate;
      },
      TContext
    >,
    'mutationFn'
  >,
) =>
  useMutation<
    TData,
    TError,
    {
      data: ReportCreate;
    },
    TContext
  >({
    mutationFn: ({ data }) => SupportService.supportReportCreate(data) as unknown as Promise<TData>,
    ...options,
  });
export type UsersServiceUsersAddInOrganizationCreateMutationResult = Awaited<
  ReturnType<typeof UsersService.usersAddInOrganizationCreate>
>;
export const useUsersServiceUsersAddInOrganizationCreate = <
  TData = UsersServiceUsersAddInOrganizationCreateMutationResult,
  TError = unknown,
  TContext = unknown,
>(
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      {
        data: AddUserInOrganization;
      },
      TContext
    >,
    'mutationFn'
  >,
) =>
  useMutation<
    TData,
    TError,
    {
      data: AddUserInOrganization;
    },
    TContext
  >({
    mutationFn: ({ data }) =>
      UsersService.usersAddInOrganizationCreate(data) as unknown as Promise<TData>,
    ...options,
  });
export type UsersServiceUsersBillingEditUpdateMutationResult = Awaited<
  ReturnType<typeof UsersService.usersBillingEditUpdate>
>;
export const useUsersServiceUsersBillingEditUpdate = <
  TData = UsersServiceUsersBillingEditUpdateMutationResult,
  TError = unknown,
  TContext = unknown,
>(
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      {
        data: AccountBillingEdit;
      },
      TContext
    >,
    'mutationFn'
  >,
) =>
  useMutation<
    TData,
    TError,
    {
      data: AccountBillingEdit;
    },
    TContext
  >({
    mutationFn: ({ data }) =>
      UsersService.usersBillingEditUpdate(data) as unknown as Promise<TData>,
    ...options,
  });
export type UsersServiceUsersEditUpdateMutationResult = Awaited<
  ReturnType<typeof UsersService.usersEditUpdate>
>;
export const useUsersServiceUsersEditUpdate = <
  TData = UsersServiceUsersEditUpdateMutationResult,
  TError = unknown,
  TContext = unknown,
>(
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      {
        data: UserEdit;
      },
      TContext
    >,
    'mutationFn'
  >,
) =>
  useMutation<
    TData,
    TError,
    {
      data: UserEdit;
    },
    TContext
  >({
    mutationFn: ({ data }) => UsersService.usersEditUpdate(data) as unknown as Promise<TData>,
    ...options,
  });
export type UsersServiceUsersInviteAcceptCreateMutationResult = Awaited<
  ReturnType<typeof UsersService.usersInviteAcceptCreate>
>;
export const useUsersServiceUsersInviteAcceptCreate = <
  TData = UsersServiceUsersInviteAcceptCreateMutationResult,
  TError = unknown,
  TContext = unknown,
>(
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      {
        data: UserInviteAccept;
      },
      TContext
    >,
    'mutationFn'
  >,
) =>
  useMutation<
    TData,
    TError,
    {
      data: UserInviteAccept;
    },
    TContext
  >({
    mutationFn: ({ data }) =>
      UsersService.usersInviteAcceptCreate(data) as unknown as Promise<TData>,
    ...options,
  });
export type UsersServiceUsersInviteCreateMutationResult = Awaited<
  ReturnType<typeof UsersService.usersInviteCreate>
>;
export const useUsersServiceUsersInviteCreate = <
  TData = UsersServiceUsersInviteCreateMutationResult,
  TError = unknown,
  TContext = unknown,
>(
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      {
        data: UserInvite;
      },
      TContext
    >,
    'mutationFn'
  >,
) =>
  useMutation<
    TData,
    TError,
    {
      data: UserInvite;
    },
    TContext
  >({
    mutationFn: ({ data }) => UsersService.usersInviteCreate(data) as unknown as Promise<TData>,
    ...options,
  });
export type UsersServiceUsersLoginAdminCreateMutationResult = Awaited<
  ReturnType<typeof UsersService.usersLoginAdminCreate>
>;
export const useUsersServiceUsersLoginAdminCreate = <
  TData = UsersServiceUsersLoginAdminCreateMutationResult,
  TError = unknown,
  TContext = unknown,
>(
  options?: Omit<UseMutationOptions<TData, TError, void, TContext>, 'mutationFn'>,
) =>
  useMutation<TData, TError, void, TContext>({
    mutationFn: () => UsersService.usersLoginAdminCreate() as unknown as Promise<TData>,
    ...options,
  });
export type UsersServiceUsersLoginUserCreateMutationResult = Awaited<
  ReturnType<typeof UsersService.usersLoginUserCreate>
>;
export const useUsersServiceUsersLoginUserCreate = <
  TData = UsersServiceUsersLoginUserCreateMutationResult,
  TError = unknown,
  TContext = unknown,
>(
  options?: Omit<UseMutationOptions<TData, TError, void, TContext>, 'mutationFn'>,
) =>
  useMutation<TData, TError, void, TContext>({
    mutationFn: () => UsersService.usersLoginUserCreate() as unknown as Promise<TData>,
    ...options,
  });
export type UsersServiceUsersNotificationServiceSyncCreateMutationResult = Awaited<
  ReturnType<typeof UsersService.usersNotificationServiceSyncCreate>
>;
export const useUsersServiceUsersNotificationServiceSyncCreate = <
  TData = UsersServiceUsersNotificationServiceSyncCreateMutationResult,
  TError = unknown,
  TContext = unknown,
>(
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      {
        data: NotificationServiceUser;
      },
      TContext
    >,
    'mutationFn'
  >,
) =>
  useMutation<
    TData,
    TError,
    {
      data: NotificationServiceUser;
    },
    TContext
  >({
    mutationFn: ({ data }) =>
      UsersService.usersNotificationServiceSyncCreate(data) as unknown as Promise<TData>,
    ...options,
  });
export type UsersServiceUsersSearchListDefaultResponse = Awaited<
  ReturnType<typeof UsersService.usersSearchList>
>;
export type UsersServiceUsersSearchListQueryResult<
  TData = UsersServiceUsersSearchListDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useUsersServiceUsersSearchListKey = 'UsersServiceUsersSearchList';
export const useUsersServiceUsersSearchList = <
  TData = UsersServiceUsersSearchListDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  {
    email,
    ordering,
    search,
    page,
    perPage,
  }: {
    email: string;
    ordering?: string;
    search?: string;
    page?: number;
    perPage?: number;
  },
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn' | 'initialData'>,
) =>
  useQuery<TData, TError>({
    queryKey: [
      useUsersServiceUsersSearchListKey,
      ...(queryKey ?? [{ email, ordering, search, page, perPage }]),
    ],
    queryFn: () => UsersService.usersSearchList(email, ordering, search, page, perPage) as TData,
    ...options,
  });

import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Input, Button, Flex, notification } from 'antd';
import { ArrowLeftOutlined, MailOutlined } from '@ant-design/icons';
import { useAuthorizationServiceAuthorizationUserRestoreCreate } from 'api/queries';
import { Authentication, useStyles } from 'features/authentication';
import { LOGIN_URL } from 'app-constants';

export const ResetPasswordForm = () => {
  const { styles } = useStyles();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const { mutate } = useAuthorizationServiceAuthorizationUserRestoreCreate({
    onSuccess: () => notification.success({ message: 'Verify your email' }),
  });

  const onSubmit = (values) => mutate({ data: values });

  return (
    <Authentication title="Did you forget your password?">
      <Form layout="vertical" form={form} onFinish={onSubmit}>
        <Form.Item
          label="E-mail"
          name="username"
          rules={[
            {
              type: 'email',
              message: 'This is an invalid e-mail.',
            },
            { required: true, message: 'E-mail is required.' },
          ]}
        >
          <Input
            size="large"
            placeholder="Enter email"
            suffix={<MailOutlined className={styles.icon} />}
          />
        </Form.Item>

        <Flex justify="space-between" className="mt-30">
          <Button
            icon={<ArrowLeftOutlined />}
            type="link"
            size="small"
            onClick={() => navigate({ pathname: LOGIN_URL }, { relative: 'path' })}
          >
            Back to Login
          </Button>

          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Flex>
      </Form>
    </Authentication>
  );
};

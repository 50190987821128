import { createStyles } from 'antd-style';
import { intoRGB } from './utils';
import { UserShort } from '../../api/requests';

export const useStyles = createStyles(
  ({ css, token }, props: { type?: string; user?: UserShort; reverse?: boolean }) => {
    const getBgColor = () => {
      switch (props.type) {
        case 'dynamic':
          return intoRGB(props?.user?.first_name && props?.user?.last_name);
        case 'primary':
          return token.colorPrimary;
        case 'light':
          return token.colorWhite;
        default:
          return undefined;
      }
    };

    const avatarBackgroundColor = getBgColor();

    return {
      avatarContent: css`
        flex-direction: ${props.reverse ? 'row-reverse' : 'row'};
      `,

      avatar: css`
        background-color: ${avatarBackgroundColor};

        .ant-avatar-string {
          display: flex;
          height: 95%;
          justify-content: center;
          align-items: center;
          align: center;
        }
      `,
    };
  },
);

import * as React from 'react';
import { Button, Dropdown } from 'antd';
import type { DropDownProps, MenuProps } from 'antd';
import type { BaseButtonProps } from 'antd/lib/button/button';
import { EllipsisOutlined } from '@ant-design/icons';

type ActionDropdownProps = {
  items?: MenuProps['items'];
  buttonType?: BaseButtonProps['type'];
};

export const ActionDropdown = ({
  items,
  buttonType = 'text',
  ...props
}: ActionDropdownProps & DropDownProps) => {
  return (
    <Dropdown menu={{ items }} trigger={['click']} placement="bottomRight" {...props}>
      <Button type={buttonType} size="small" icon={<EllipsisOutlined />} />
    </Dropdown>
  );
};

import React from 'react';
import * as ReactDOM from 'react-dom/client';
import { App } from 'App';
import * as serviceWorker from './serviceWorker';

import './styles/reset.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(<App />);

serviceWorker.unregister();

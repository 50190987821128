import * as React from 'react';
import { SessionContextState } from 'contexts/SessionContext';

const defaultContextValue: SessionContextState = {
  session: null,
  onLogin: () => null,
  onLogout: () => null,
};

export default React.createContext<SessionContextState>(defaultContextValue);

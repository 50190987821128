import * as React from 'react';
import { Routes, Route } from 'react-router-dom';
import {
  InvitationForm,
  LoginForm,
  ResetPasswordConfirmForm,
  ResetPasswordForm,
  NotFound,
} from 'features/authentication';

export const PublicRoutes = () => (
  <Routes>
    <Route path="authentication">
      <Route path="login" element={<LoginForm />} />
      <Route path="invitation" element={<InvitationForm />} />
      <Route path="reset-password" element={<ResetPasswordForm />} />
      <Route path="restore" element={<ResetPasswordConfirmForm />} />

      <Route path="*" element={<NotFound />} />
    </Route>
  </Routes>
);

import * as React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { AppLayout } from 'components/AppLayout';
import { Applications } from 'features/applications';
import { UsersPage, UserPermissions, UserInvite } from 'features/users';
import { useUserPermissions } from 'hooks';

export const PrivateRoutes = () => {
  const { isAdmin } = useUserPermissions();
  return (
    <AppLayout>
      <Routes>
        <Route path="applications" element={<Applications />} />

        {isAdmin && (
          <Route path="users">
            <Route index element={<UsersPage />} />
            <Route path="invite" element={<UserInvite />} />
            <Route path="permissions/:id" element={<UserPermissions />} />
          </Route>
        )}

        <Route path="*" element={<Navigate to="/applications" replace />} />
      </Routes>
    </AppLayout>
  );
};

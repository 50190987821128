import * as React from 'react';
import { Flex, Form, FormInstance, Select, Switch } from 'antd';
import { Application } from 'api/requests';
import { ApplicationsServiceApplicationsAccessReadDefaultResponse } from 'api/queries';
import { useStyles } from 'features/users';

type Props = {
  application: Application;
  allowedApp: ApplicationsServiceApplicationsAccessReadDefaultResponse;
  form: FormInstance<Record<string, unknown>>;
};

export const UserPermissionRole = ({ application, allowedApp, form }: Props) => {
  const { styles } = useStyles();

  const active = Form.useWatch([`${application.id}`, 'active'], form);

  React.useEffect(() => {
    if (allowedApp) {
      form.setFieldValue([application?.id, 'active'], allowedApp.application);
      form.setFieldValue([application?.id, 'role'], allowedApp.role);
    } else {
      form.resetFields();
    }
  }, [allowedApp, form, application]);

  return (
    <Flex gap="large" wrap="wrap" justify="end">
      <Form.Item label="Active" name={[`${application.id}`, 'active']}>
        <Switch />
      </Form.Item>

      <Form.Item
        label="Select Role"
        name={[`${application.id}`, 'role']}
        className={styles.roleSelect}
        rules={[{ required: active, message: 'Please select application role' }]}
      >
        <Select
          size="large"
          disabled={!active}
          options={application?.instance_type?.roles?.map((role) => ({ value: role, label: role }))}
        />
      </Form.Item>
    </Flex>
  );
};

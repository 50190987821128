import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Input, Button, Flex } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useAccountServiceAccountConfirmRestoreCreate } from 'api/queries';
import { Authentication } from 'features/authentication';
import { LOGIN_URL } from 'app-constants';
import { useURLParams } from 'hooks';
import { VERIFY_PASSWORD_REGEX } from 'utils';

export const ResetPasswordConfirmForm = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const params = useURLParams();
  const token = params.get('token');

  React.useEffect(() => {
    if (!token) {
      navigate({ pathname: LOGIN_URL }, { relative: 'path' });
    }
  }, [token]);

  const { mutate } = useAccountServiceAccountConfirmRestoreCreate({
    onSuccess: () => navigate({ pathname: LOGIN_URL }, { relative: 'path' }),
  });

  const onSubmit = (values) => {
    mutate({ data: { ...values, token } });
  };

  return (
    <Authentication title="Reset your password">
      <Form layout="vertical" form={form} onFinish={onSubmit}>
        <Form.Item
          label="New password"
          name="password"
          rules={[
            {
              pattern: VERIFY_PASSWORD_REGEX,
              message:
                'Password must be at least 8 characters long and contain at least one number, one uppercase letter and one special character.',
            },
            { required: true, message: 'Password is required.' },
          ]}
        >
          <Input.Password size="large" placeholder="Enter password" />
        </Form.Item>
        <Form.Item
          label="Repeat password"
          name="confirm_password"
          rules={[
            {
              pattern: VERIFY_PASSWORD_REGEX,
              message:
                'Password must be at least 8 characters long and contain at least one number, one uppercase letter and one special character.',
            },
            {
              validator: (_, value, callback): void => {
                if (value && value !== form.getFieldValue('password')) {
                  callback('Passwords do not match.');
                } else {
                  callback();
                }
              },
            },
            { required: true, message: 'Repeat password is required.' },
          ]}
        >
          <Input.Password size="large" placeholder="Enter repeat password" />
        </Form.Item>

        <Flex justify="space-between" className="mt-30">
          <Button
            icon={<ArrowLeftOutlined />}
            type="link"
            size="small"
            onClick={() => navigate({ pathname: LOGIN_URL }, { relative: 'path' })}
          >
            Back to Login
          </Button>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Flex>
      </Form>
    </Authentication>
  );
};

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type LogType = {
  code: string;
  readonly modified_at?: string;
  readonly created_at?: string;
  name?: string | null;
  name_ro?: string | null;
  name_en?: string | null;
  name_ru?: string | null;
  priority?: LogType.priority;
};
export namespace LogType {
  export enum priority {
    LOW = 'low',
    NORMAL = 'normal',
    HIGH = 'high',
  }
}

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type OrganizationAddUser = {
  role?: OrganizationAddUser.role;
  emails: Array<string>;
};
export namespace OrganizationAddUser {
  export enum role {
    ADMIN = 'admin',
    EMPLOYEE = 'employee',
  }
}

import { createStyles } from 'antd-style';

export const useStyles = createStyles(({ css, token }) => {
  return {
    appCard: css`
      height: 300px;
      width: 300px;
      border-radius: 8px;
      box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.04), 0 3px 6px 0 rgba(0, 0, 0, 0.05),
        0 10px 10px 0 rgba(0, 0, 0, 0.04), 0 23px 14px 0 rgba(0, 0, 0, 0.03),
        0 41px 16px 0 rgba(0, 0, 0, 0.01), 0 64px 18px 0 rgba(0, 0, 0, 0);
      position: relative;

      .ant-card-body {
        height: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        ::after {
          display: none;
        }
      }
    `,

    iconBox: css`
      height: 82px;
      width: 76px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${token.colorPrimary};
      position: absolute;
      top: -20px;
      left: -5px;
      z-index: 99;
      transform: skew(-22deg);

      .anticon {
        transform: skew(22deg);
        font-size: 30px;
        color: #fff;
      }
    `,

    appButton: css`
      color: ${token.colorPrimary};
      transition: color 300ms ease-in-out;

      .anticon {
        font-size: 10px;
        color: ${token.colorPrimary};
        transition: color 300ms ease-in-out;
      }
      :hover {
        color: ${token.colorLinkHover};
        .anticon {
          color: ${token.colorLinkHover};
        }
      }
    `,
  };
});

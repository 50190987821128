import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Flex, Button, Input, notification } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useURLParams } from 'hooks';
import { VERIFY_PASSWORD_REGEX } from 'utils';
import { Authentication } from 'features/authentication';
import { useUsersServiceUsersInviteAcceptCreate } from 'api/queries';
import { ApiError } from 'api/requests';
import { LOGIN_URL } from 'app-constants';

export const InvitationForm = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const params = useURLParams();
  const token = params.get('token');

  React.useEffect(() => {
    if (!token) {
      navigate({ pathname: LOGIN_URL }, { relative: 'path' });
    }
  }, [token]);

  const { mutate } = useUsersServiceUsersInviteAcceptCreate({
    onSuccess: () => navigate({ pathname: LOGIN_URL }, { relative: 'path' }),
    onError: (e) => {
      if (e instanceof ApiError) {
        notification.error({
          message: 'Error',
          description: e.body.detail,
        });
      }
    },
  });

  const onSubmit = (values) => {
    mutate({ data: { ...values, invite_token: token } });
  };

  return (
    <Authentication title="Registration">
      <Form layout="vertical" form={form} onFinish={onSubmit} autoComplete="off">
        <Form.Item
          label="Password"
          name="password"
          rules={[
            { required: true, message: 'Enter your account password.' },

            {
              pattern: VERIFY_PASSWORD_REGEX,
              message:
                'Password must be at least 8 characters long and contain at least one number, one uppercase letter and one special character.',
            },
          ]}
        >
          <Input.Password size="large" placeholder="Enter password" autoComplete="off" />
        </Form.Item>

        <Flex justify="space-between" className="mt-30">
          <Button
            icon={<ArrowLeftOutlined />}
            type="link"
            size="small"
            onClick={() => navigate({ pathname: LOGIN_URL }, { relative: 'path' })}
          >
            Back to Login
          </Button>

          <Button type="primary" disabled={!token} htmlType="submit">
            Onboard
          </Button>
        </Flex>
      </Form>
    </Authentication>
  );
};

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UserShort } from './UserShort';
export type ApplicationUserDetails = {
  role: ApplicationUserDetails.role;
  user: UserShort;
  readonly teams?: string;
  readonly application_role?: string;
};
export namespace ApplicationUserDetails {
  export enum role {
    ADMIN = 'admin',
    EMPLOYEE = 'employee',
  }
}

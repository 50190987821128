/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type UserShort = {
  id: number;
  email?: string | null;
  first_name?: string | null;
  last_name?: string | null;
  phone?: string;
  photo?: string | null;
  last_login?: string | null;
  position?: string | null;
  readonly sso_id?: number;
  readonly teams?: string;
  language?: UserShort.language;
  is_active?: boolean;
};
export namespace UserShort {
  export enum language {
    EN = 'en',
    RU = 'ru',
    RO = 'ro',
  }
}

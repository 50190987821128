import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Card, Divider, Flex, Form, Input, Row, Col, Typography, notification } from 'antd';
import { UserAddOutlined } from '@ant-design/icons';
import { UserSteps } from 'features/users';
import { useUsersServiceUsersInviteCreate } from 'api/queries';
import { ApiError } from 'api/requests';
import { SessionContext } from 'contexts/SessionContext';
import { OrganizationUser } from 'types';

const { Title } = Typography;

const inviteUrl = `${window.location.protocol}//${window.location.hostname}/authentication/invitation/?token=`;

export const UserInvite = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { session } = React.useContext(SessionContext);

  const { mutate, isPending } = useUsersServiceUsersInviteCreate<OrganizationUser>({
    onError: (error) => {
      if (error instanceof ApiError && error.body.detail) {
        notification.error({ message: error.body.detail });
      }
    },
    onSuccess: (data) => {
      if (data?.[0]?.id) {
        navigate(
          { pathname: `/users/permissions/${data?.[0]?.id}`, search: '?type=invite' },
          { relative: 'path' },
        );
      }
    },
  });

  const onSubmit = (values) => {
    if (session?.data?.organization?.id) {
      mutate({
        data: {
          ...values,
          organization_id: String(session?.data?.organization?.id),
          invite_url: inviteUrl,
        },
      });
    }
  };

  return (
    <>
      <Flex className="mb-20">
        <Title level={3}>Invite new user</Title>
      </Flex>

      <Row className="mb-30">
        <Col span={24} sm={24} md={20} lg={16} xl={14} xxl={10}>
          <UserSteps />
        </Col>
      </Row>
      <Row>
        <Col span={24} sm={24} md={20} lg={16} xl={14} xxl={10}>
          <Card>
            <Form form={form} layout="vertical" onFinish={onSubmit}>
              <Form.Item
                label="First name"
                name="first_name"
                rules={[{ required: true, message: 'First name is required.' }]}
              >
                <Input size="large" />
              </Form.Item>

              <Form.Item
                label="Last name"
                name="last_name"
                rules={[{ required: true, message: 'Last name is required.' }]}
              >
                <Input size="large" />
              </Form.Item>
              <Form.Item
                name="email"
                label="E-mail"
                rules={[
                  { required: true, message: 'Please input E-mail!' },
                  { type: 'email', message: 'The input is not valid E-mail!' },
                ]}
              >
                <Input size="large" type="email" placeholder="Enter email..." />
              </Form.Item>

              <Divider />

              <Flex className="mt-20" justify="space-between" align="center" wrap="wrap">
                <Button onClick={() => navigate('/users', { relative: 'path' })}>Cancel</Button>
                <Button htmlType="submit" icon={<UserAddOutlined />} loading={isPending}>
                  Invite and add in your organization
                </Button>
              </Flex>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
};

import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Result, Button } from 'antd';
import { LOGIN_URL } from 'app-constants';
import { Authentication } from 'features/authentication';

export const NotFound = () => {
  const navigate = useNavigate();

  return (
    <Authentication>
      <Result
        status={404}
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Button
            type="primary"
            onClick={() => navigate({ pathname: LOGIN_URL }, { relative: 'path' })}
          >
            Redirect to login
          </Button>
        }
      />
    </Authentication>
  );
};

import * as React from 'react';
import { SessionContext } from 'contexts/SessionContext';
import { UserRole } from 'types';

export const useUserPermissions = () => {
  const { session } = React.useContext(SessionContext);

  const isAdmin = session?.data?.role === UserRole.admin;

  return { isAdmin };
};

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type AccountAccess = {
  readonly id?: number;
  role: AccountAccess.role;
  readonly account?: {
    readonly id?: number;
    name: string;
    billing_first_name?: string | null;
    billing_registration_number?: string | null;
    billing_last_name?: string | null;
    billing_phone?: string | null;
    billing_email?: string | null;
    billing_address1?: string | null;
    billing_address2?: string | null;
    billing_city?: string | null;
    billing_zip?: string | null;
    billing_industry?: number | null;
    billing_country?: number | null;
  };
};
export namespace AccountAccess {
  export enum role {
    ADMIN = 'admin',
    ACCOUNTANT = 'accountant',
  }
}
